import Joi from 'joi'
import AbstractModel from './AbstractModel'

export default class PublicCoinInfoModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      symbol: Joi.string().required(),
      info: Joi.object().keys({
        price: Joi.object().keys({
          usd: Joi.number().allow(null),
          aud: Joi.number().allow(null)
        }),
        priceChanged: Joi.object().keys({
          usd: Joi.number().allow(null),
          aud: Joi.number().allow(null)
        }),
        high24: Joi.object().keys({
          usd: Joi.number().allow(null),
          aud: Joi.number().allow(null)
        }),
        low24: Joi.object().keys({
          usd: Joi.number().allow(null),
          aud: Joi.number().allow(null)
        }),
        marketCap: Joi.object().keys({
          usd: Joi.number().allow(null),
          aud: Joi.number().allow(null)
        }),
        tradingVolume: Joi.object().keys({
          usd: Joi.number().allow(null),
          aud: Joi.number().allow(null)
        })
      })
    }
  }

  static fromJson (data, context, options) {
    return new PublicCoinInfoModel({
      ...data
    })
  }
}
