/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'datetime': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11 13V7h2v4.845l2.78 1.605-1 1.732L11 13z" _fill="#fff"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-2 0a7 7 0 11-14 0 7 7 0 0114 0z" _fill="#fff"/>'
  }
})
