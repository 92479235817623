import { HolderModel } from 'src/models'
import { ratesDAO } from 'src/daos'
import { REGISTRY } from 'src/services'
import { RATES } from 'src/remotes'

export const DAO_INITIALIZE = 'daos/initialize'

export default () => ({
  namespaced: true,
  state () {
    return {
      byName: {},
      byContract: {}
    }
  },
  getters: {
    getDAO: state => name => {
      const holder = state.byName[name]
      return holder == null
        ? null
        : holder.value
    },
    getDAOByContract: state => contract => {
      const holder = state.byContract[contract]
      return holder == null
        ? null
        : holder.value
    }
  },
  mutations: {
    [DAO_INITIALIZE]: (state, { name, contract, dao }) => {
      if (name) {
        state.byName = {
          ...state.byName,
          [name]: new HolderModel(Object, {
            value: dao
          })
        }
      }
      if (contract) {
        state.byContract = {
          ...state.byContract,
          [contract]: new HolderModel(Object, {
            value: dao
          })
        }
      }
    }
  },
  actions: {
    async initializeRatesDAO ({ commit }) {
      const client = REGISTRY.getService('ratesSocketClient')
      ratesDAO.connect(client, RATES)
      commit(DAO_INITIALIZE, { name: 'RatesDAO', contract: null, dao: ratesDAO })
    }
  }
})
