import {
  REGISTRY,
  plasmaSocketClientFactory,
  ratesSocketClientFactory,
  passportClientFactory
} from 'src/services'

export default {
  async beforeReady ({ store }) {
    try {
      if (process.client) {
        const config = store.getters['config/config']
        REGISTRY
          .registerService('plasmaSocketClient', plasmaSocketClientFactory(config))
          .registerService('ratesSocketClient', ratesSocketClientFactory(config))
          .registerService('passportClient', passportClientFactory(config))
      }
    } catch (e) {
      console.log(e)
      throw e
    }
  }
}
