import Joi from 'joi'
import AbstractModel from './AbstractModel'

export default class PlasmaOrderbookGroupedModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      timestamp: Joi.date().required(),
      ask: Joi.array().items(
        Joi.object().keys({
          index: Joi.number().required(),
          price: Joi.string().required(),
          baseTokenAmount: Joi.string().required(),
          baseTokenCumulativeAmount: Joi.string().required(),
          quoteTokenAmount: Joi.string().required(),
          quoteTokenCumulativeAmount: Joi.string().required(),
          ownerOrdersInLevel: Joi.boolean()
        })
      ).required(),
      bid: Joi.array().items(
        Joi.object().keys({
          index: Joi.number().required(),
          price: Joi.string().required(),
          baseTokenAmount: Joi.string().required(),
          baseTokenCumulativeAmount: Joi.string().required(),
          quoteTokenAmount: Joi.string().required(),
          quoteTokenCumulativeAmount: Joi.string().required(),
          ownerOrdersInLevel: Joi.boolean()
        })
      ).required()
    }
  }

  static fromJson (data) {
    return new PlasmaOrderbookGroupedModel({
      ...data
    })
  }
}
