import { LoadableListModel, ExtendedCurrencyModel } from 'src/models'
import exchangeBackendService from 'src/exchangeBackendService'

export const CURRENCIES_LOADING = 'currencies/loading'
export const CURRENCIES_LOADED = 'currencies/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      list: new LoadableListModel(ExtendedCurrencyModel)
    }
  },
  mutations: {
    [CURRENCIES_LOADING]: (state) => {
      state.list = new LoadableListModel(ExtendedCurrencyModel, {
        isLoaded: false,
        isLoading: true,
        value: state.list.value.map(ExtendedCurrencyModel.fromJson)
      })
    },
    [CURRENCIES_LOADED]: (state, { currencies }) => {
      state.list = new LoadableListModel(ExtendedCurrencyModel, {
        isLoaded: true,
        isLoading: false,
        value: currencies.map(ExtendedCurrencyModel.fromJson)
      })
    }
  },
  getters: {
    currencies: state => state.list,
    getCurrency: state => address => state.list.isLoaded && state.list.value.find(
      currency => currency.address.toLowerCase() === address.toLowerCase()
    ),
    getCurrencyBySymbol: state => symbol => state.list.isLoaded && state.list.value.find(
      currency => currency.symbol.toLowerCase() === symbol.toLowerCase()
    ),
    isLoaded: state => state.list.isLoaded
  },
  actions: {
    async loadCurrencies ({ commit }) {
      commit(CURRENCIES_LOADING)
      const { data } = await exchangeBackendService.getCurrencies()
      const currencies = data.map(ExtendedCurrencyModel.fromJson)
      currencies.sort((a, b) => a.sortOrder - b.sortOrder)
      commit(CURRENCIES_LOADED, { currencies })
    }
  }
})
