import axios from 'axios'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import configProvider from 'src/configProvider'

const config = configProvider.getConfig()
const serverConfig = global.__SERVER_CONFIG__

export const withAuthorization = (authorization, config = {}) => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${authorization}`
  }
})

export const withAuthorizaionSignature = (signature, config = {}) => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Signature ${signature}`
  }
})

export const withAuthorizationAndVerification = (authorization, verification, config = {}) => ({
  ...config,
  headers: {
    ...config.headers,
    ...omitBy({
      authorization: authorization == null ? null : `Bearer ${authorization}`,
      'x-passport-otp': verification == null ? null : `APP ${verification}`
    }, isNil)
  }
})

const PUBLIC_BACKEND = axios.create(config.publicService.endpoint)
const SERVER_PUBLIC_BACKEND = serverConfig ? axios.create(serverConfig.publicService.endpoint) : null

const BACKEND = axios.create(config.backendService.endpoint)
const SERVER_EXCHANGE_BACKEND = serverConfig ? axios.create(serverConfig.backendService.endpoint) : null

export const PLASMA = axios.create(config.plasmaService.endpoint)
export const RATES = axios.create(config.ratesService.endpoint)
export const PASSPORT = axios.create(config.passportService.endpoint)
export const PROFILE = axios.create(config.profileService.endpoint)

export const getExchangeBackend = () => {
  if (process.client) {
    return BACKEND
  } else {
    return SERVER_EXCHANGE_BACKEND
  }
}

export const getPublicBackend = () => {
  if (process.client) {
    return PUBLIC_BACKEND
  } else {
    return SERVER_PUBLIC_BACKEND
  }
}
