export function describeAxiosException (exception) {
  let result
  if (exception.config instanceof Object) {
    result = `Request [method=${exception.config.method.toUpperCase()}] to [url=${exception.config.url}] failed. `
    if (exception.config.data) {
      result += `Request info: [request_body=${JSON.stringify(exception.config.data)}], `
    }
  }

  if (exception.response instanceof Object) {
    result += 'Response info: '
    result += `[status=${exception.response.status}(${exception.response.statusText})], `
    result += `[response_body=${JSON.stringify(exception.response.data)}]`
  }

  if (exception.errno) {
    result += `[nodeerror=${exception.errno}]`
  }

  if (exception.code === 'ECONNABORTED') {
    result += exception.message
  }

  return result
}
