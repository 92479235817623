import Joi from 'joi'
import parseURL from 'url-parse'
import AbstractModel from './AbstractModel'

export default class CachedImageModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      id: Joi.string().allow(null, ''),
      url: Joi.string().required()
    }
  }

  static fromServerModel (data, { baseUrl }) {
    if (data == null) return data
    return data == null ? null : new CachedImageModel({
      id: data.public_id,
      url: data.secure_url
        ? `${baseUrl}${parseURL(data.secure_url).pathname}`
        : data.url
    })
  }
}
