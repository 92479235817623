/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'burger2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2 5v2h20V5H2zm0 6v2h20v-2H2zm0 6v2h20v-2H2z" _fill="#fff" opacity=".5"/>'
  }
})
