/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'about-us/icon_06': {
    width: 84,
    height: 91,
    viewBox: '0 0 84 91',
    data: '<path pid="0" d="M83.17 15.74s-23.134 15.715-29.391 9.473c-6.258-6.242 1.647-14.106 6.607-9.781 4.96 4.325-1.085 16.866-15.405 31.185C31.706 59.84 4.858 79.213 4.858 79.213" _stroke="#FDA431" stroke-width=".5" stroke-miterlimit="10"/>'
  }
})
