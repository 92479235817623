import EventEmitter from 'events'
import uniqid from 'uniqid'

export default class PlasmaChannel extends EventEmitter {
  constructor (client, pattern, payload) {
    super()
    this.client = client
    this.pattern = pattern
    this.subscribeRequestId = uniqid()

    const handleSubscribed = m => {
      if (m.type === 'SUBSCRIBED' && m.requestId === this.subscribeRequestId) {
        client.removeListener('message', handleSubscribed)
        this.subscriptionId = m.subscriptionId
        this.emit('subscribed')
      }
    }

    const handleMessage = m => {
      if (m.type === 'MESSAGE' && m.subscriptionId === this.subscriptionId) {
        this.emit('message', m.message)
      }
    }

    client.on('message', handleSubscribed)
    client.on('message', handleMessage)

    this.client.send({
      type: 'SUBSCRIBE',
      pattern,
      payload,
      requestId: this.subscribeRequestId
    })
  }

  // send (payload) {
  //   this.client.send({
  //     type: 'MESSAGE',
  //     requestId: uniqid()
  //   })
  // }

  close () {
    try {
      this.client.send({
        type: 'UNSUBSCRIBE',
        requestId: uniqid(),
        subscriptionId: this.subscriptionId
      })
      this.subscriptionId = null
      this.client = null
    } catch (e) {
      // ignore
    }
  }
}

PlasmaChannel.segment = (str) => `^(?:.*/)?${str}(?:/.*)?$`
