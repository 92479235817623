import Joi from 'joi'
import AbstractModel from './AbstractModel'

const schemaFactory = (type) => ({
  value: Joi.object().instance(type).required()
})

export default class HolderModel extends AbstractModel {
  constructor (type, data) {
    super(data, schemaFactory(type))
    Object.freeze(this)
  }
}
