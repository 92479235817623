import configProvider from 'src/configProvider'

export default class Roistat {
  constructor () {
    const config = configProvider.getConfig()
    this.isEnabled = config.roistat != null && config.roistat.key != null
    this.isReady = false

    this.delayedQueue = []
  }

  ready () {
    this.isReady = true
  }

  async handleDelayedQueue () {
    this.isProcessingDelayedQueue = true

    while (this.delayedQueue.length > 0) {
      const { type, payload } = this.delayedQueue.shift()
      switch (type) {
        case 'sendEvent':
          window.roistat.event.send(payload.eventName, payload.properties)
          break

        case 'identify':
          window.roistat.authClientById(payload.userId)
          break

        default:
          break
      }
    }

    this.isProcessingDelayedQueue = false
  }

  async waitHandlingDelayedQueueIfNeeded () {
    if (this.isProcessingDelayedQueue) {
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.isProcessingDelayedQueue) {
            clearInterval(interval)
            resolve()
          }
        }, 100)
      })
    }
  }

  async sendEvent ({ eventName, properties }) {
    if (!this.isEnabled) {
      return
    }

    if (!this.isReady) {
      this.delayedQueue.push({
        type: 'sendEvent',
        payload: {
          eventName,
          properties
        }
      })
    } else {
      await this.waitHandlingDelayedQueueIfNeeded()
      window.roistat.event.send(eventName, properties)
    }
  }

  async identify ({ userId }) {
    if (!this.isEnabled) {
      return
    }

    if (!this.isReady) {
      this.delayedQueue.push({
        type: 'identify',
        payload: {
          userId
        }
      })
    } else {
      await this.waitHandlingDelayedQueueIfNeeded()
      window.roistat.authClientById(userId)
    }
  }
}
