/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'facebook': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" opacity=".8" d="M14.172 0H.828A.828.828 0 000 .828v13.344c0 .457.37.828.828.828h7.184V9.192H6.057V6.928h1.955v-1.67c0-1.937 1.183-2.992 2.912-2.992.827 0 1.54.061 1.746.09V4.38h-1.198c-.94 0-1.122.447-1.122 1.102v1.445h2.241L12.3 9.191h-1.95V15h3.822c.457 0 .828-.37.828-.828V.828A.828.828 0 0014.172 0z" _fill="#111"/>'
  }
})
