import { LoadableListModel, MarketModel, PublicMarketDAOModel } from 'src/models'
import { PLASMA } from 'src/remotes'
import { REGISTRY } from 'src/services'

export const MARKETS_LOADING = 'publicMarkets/loading'
export const MARKETS_LOADED = 'publicMarkets/loaded'

export default () => ({
  namespaced: true,
  state: {
    list: new LoadableListModel(PublicMarketDAOModel)
  },
  mutations: {
    [MARKETS_LOADING]: (state) => {
      state.list = state.list.loading()
    },
    [MARKETS_LOADED]: (state, { markets }) => {
      state.list = state.list.loaded(...markets)
    }
  },
  getters: {
    markets: state => state.list,
    getMarketBySymbol: state => symbol => state.list.isLoaded && state.list.value.find(
      market => market.market.symbol === symbol
    ),
    activeMarket (state, getters, rootState) {
      return getters.getMarketBySymbol(rootState.ui.activeMarketSymbol)
    },
    isLoaded: state => state.list.isLoaded
  },
  actions: {
    async fetchMarkets ({ state, commit }) {
      const { data } = await PLASMA.get('public/markets')
      const markets = data.map(MarketModel.fromJson)
      markets.sort((a, b) => a.sortOrder - b.sortOrder)
      return markets
    },
    async loadMarkets ({ state, commit, dispatch, rootState, rootGetters }) {
      commit(MARKETS_LOADING)

      const models = await dispatch('fetchMarkets')
      state.list.forEach(market => {
        market.dao.disconnect()
        market.dao.removeAllListeners()
      })
      const markets = await Promise.all(
        models.map(model => dispatch('initMarket', { model }))
      )

      commit(MARKETS_LOADED, {
        markets
      })
    },
    async reconnectMarkets ({ state }) {
      const plasmaSocketClient = REGISTRY.getService('plasmaSocketClient')
      state.list.forEach(market => {
        market.dao.disconnect()
        market.dao.connect(plasmaSocketClient, PLASMA)
      })
    },
    async initMarket ({ state, dispatch, rootGetters }, { model }) {
      const plasmaSocketClient = REGISTRY.getService('plasmaSocketClient')
      const market = PublicMarketDAOModel.fromMarketModel(model)
      market.dao.connect(plasmaSocketClient, PLASMA)
      return market
    },
    async disconnect ({ state }) {
      state.list.forEach(market => {
        market.dao.disconnect()
      })
    }
  }
})
