import { mapActions } from 'vuex'
import TxButton from '@/components/lib/TxButton/TxButton.vue'
import VerificationWarning from '@/components/lib/VerificationWarning/VerificationWarning.vue'
import BaseModal from '../BaseModal/BaseModal.vue'

export default {
  components: {
    BaseModal,
    TxButton,
    VerificationWarning
  },
  props: {
    logoutTimeSeconds: String
  },
  data () {
    return {
      interval: null,
      counter: 0
    }
  },
  created () {
    this.counter = this.logoutTimeSeconds
    if (!this.interval) {
      this.interval = setInterval(() => {
        if (this.counter <= 0) {
          clearInterval(this.interval)
          this.handleLogout()
          this.closeModal()
          return
        }
        this.counter--
      }, 1000)
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  computed: {
    minutes () {
      return this.counter && Math.floor(this.counter / 60)
    },
    seconds () {
      return this.counter && (this.counter % 60)
    }
  },
  methods: {
    ...mapActions({
      closeModal: 'modals/close',
      updateSessionTimer: 'ui/updateSessionTimer',
      logout: 'passport/signout'
    }),
    async handleLogout () {
      await this.logout()
    },
    handleSubmit () {
      this.updateSessionTimer()
      this.closeModal()
    }
  }
}
