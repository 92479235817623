import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export default class BlogArticleCommentsRequest extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      page: Joi.number().default(0),
      size: Joi.number().default(3)
    }
  }
}
