const ONE_HOUR = 60 * 60 * 1000

class SimpleCache {
  constructor () {
    this.cache = new Map()
    this.cacheExpireTime = new Map()
  }

  get (key) {
    const result = this.cache.get(key)

    const expireTime = this.cacheExpireTime.get(key)
    if (expireTime < Date.now()) {
      return null
    }

    return result
  }

  set (key, value) {
    this.cache.set(key, value)
    this.cacheExpireTime.set(key, Date.now() + ONE_HOUR)
  }

  async getOrStore (key, storeFunction) {
    const value = this.get(key)

    if (value) {
      return value
    }

    const toStore = await storeFunction()

    this.set(key, toStore)

    return toStore
  }
}

export default SimpleCache
