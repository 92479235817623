/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11 3v8H3v2h8v8h2v-8h8v-2h-8V3h-2z" _fill="#000"/>'
  }
})
