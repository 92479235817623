/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'about-us/icon_05': {
    width: 28,
    height: 26,
    viewBox: '0 0 28 26',
    data: '<path pid="0" d="M13.58 12.738L10.62 5.556a4.152 4.152 0 00-7.046-1.063 4.133 4.133 0 00.561 5.843c.546.449 1.185.736 1.831.87l7.616 1.532zM13.936 13.033l7.596 1.555a4.152 4.152 0 012.393 6.712 4.133 4.133 0 01-5.841.567 4.232 4.232 0 01-1.205-1.63l-2.943-7.204z" _stroke="#FDA431" stroke-width=".75" stroke-miterlimit="10"/>'
  }
})
