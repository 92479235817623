import createPersistedState from 'vuex-persistedstate'

export default {
  async beforeReady ({ router, store }) {
    try {
      if (process.client) {
        createPersistedState({
          key: 'persistedTimexLandingState',
          storage: window.localStorage,
          paths: [
            'ui',
            'cookies'
          ]
        })(store)

        createPersistedState({
          key: 'persistedTimexSharedState',
          storage: window.localStorage,
          paths: [
            'passport',
            'language',
            'auth'
          ]
        })(store)
        try {
          if (process.client) {
            await store.dispatch('passport/recover')
          }
        } catch (e) {
          // ignore
        }
      }
      if (process.client) {
        router.afterEach((to) => {
          document.body.scrollTop = 0 // For Safari
          document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
        })
      }
    } catch (e) {
      // eslint-disable-next-line
      console.log(e)
      throw e
    }
  }
}
