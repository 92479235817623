/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gift': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M3.334 3.333A1.667 1.667 0 001.667 5v4.167H4.32a2.472 2.472 0 01-.153-.834 2.5 2.5 0 012.5-2.5c.285.003.566.055.833.154V3.333H3.334zm5.833 0v2.654c.267-.1.549-.151.833-.154a2.5 2.5 0 012.5 2.5 2.47 2.47 0 01-.153.834h5.987V5a1.667 1.667 0 00-1.667-1.667h-7.5zM6.667 7.5a.833.833 0 000 1.667H7.5v-.834a.833.833 0 00-.833-.833zM10 7.5a.833.833 0 00-.833.833v.834H10A.833.833 0 0010 7.5zm-8.333 3.333V15a1.667 1.667 0 001.667 1.667H7.5v-4.655l-1.666 1.666L4.655 12.5l1.667-1.667H1.667zm8.678 0l1.667 1.667-1.178 1.178-1.667-1.666v4.655h7.5A1.667 1.667 0 0018.334 15v-4.167h-7.989z" _fill="#fff" fill-opacity=".7"/>'
  }
})
