/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logout': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.333 10L15 6.667v2.5H6.667v1.667H15v2.5L18.333 10zm-5-5.833A1.679 1.679 0 0011.667 2.5h-7.5A1.68 1.68 0 002.5 4.167v11.667A1.68 1.68 0 004.167 17.5h7.5a1.68 1.68 0 001.666-1.666v-1.667h-1.666v1.667h-7.5V4.167h7.5v1.666h1.666V4.167z" _fill="#fff" />'
  }
})
