import Joi from 'joi'
import AbstractModel from './AbstractModel'

export default class ExtendedCurrencyModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      symbol: Joi.string().required(),
      name: Joi.string().required(),
      address: Joi.string().required(),
      icon: Joi.string().allow(null),
      background: Joi.string().allow(null),
      fiatSymbol: Joi.string().allow(null, ''),
      displayDecimals: Joi.number().required(),
      decimals: Joi.number().min(1).required(),
      tradeDecimals: Joi.number().min(1).required(),
      crypto: Joi.boolean(),
      depositEnabled: Joi.boolean(),
      withdrawalEnabled: Joi.boolean(),
      transferEnabled: Joi.boolean(),
      active: Joi.boolean(),
      link: Joi.string().allow(null, ''),
      showOnLanding: Joi.boolean(),
      showOnCoinPages: Joi.boolean(),
      sortOrder: Joi.number().required(),
      withdrawPlugins: Joi.array().items(Joi.object()).allow(null)
    }
  }

  get key () {
    return `currency-${this.address}`
  }

  static fromJson (data, options) {
    if (data == null) {
      return null
    }

    return new ExtendedCurrencyModel({
      symbol: data.symbol,
      name: data.name,
      address: data.address,
      icon: data.icon,
      background: data.background,
      fiatSymbol: data.fiatSymbol,
      decimals: data.decimals,
      tradeDecimals: data.tradeDecimals,
      displayDecimals: data.displayDecimals,
      crypto: data.crypto,
      depositEnabled: data.depositEnabled,
      withdrawalEnabled: data.withdrawalEnabled,
      transferEnabled: data.transferEnabled,
      active: data.active,
      link: data.link,
      showOnLanding: data.showOnLanding,
      showOnCoinPages: data.showOnCoinPages,
      sortOrder: data.sortOrder,
      withdrawPlugins: data.withdrawPlugins
    })
  }
}
