/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'about-us/icon_08': {
    width: 24,
    height: 21,
    viewBox: '0 0 24 21',
    data: '<path pid="0" d="M1 13.148L23.42 8.032" _stroke="#FDA431" stroke-width=".5" stroke-miterlimit="10"/>'
  }
})
