import initializeSegment from 'src/segment'
import { roistat } from 'src/analytics'

export default {
  async beforeReady ({ router, store }) {
    if (process.client) {
      const config = store.getters['config/config']

      if (config.segment) {
        initializeSegment(router, config.segment)
      }

      if (config.roistat && config.roistat.key) {
        window.onRoistatAllModulesLoaded = function () {
          roistat.ready()
          roistat.handleDelayedQueue()
        }

        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.innerHTML = `(function(w, d, s, h, id) {
            w.roistatProjectId = id; w.roistatHost = h;
            var p = d.location.protocol == "https:" ? "https://" : "http://";
            var u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
            var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
        })(window, document, 'script', 'cloud.roistat.com', '${config.roistat.key}');`
        document.body.appendChild(script)
      }

      if (config.pixel && config.pixel.id) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.innerHTML = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', ${config.pixel.id});fbq('track', 'PageView');`
        document.body.appendChild(script)

        const noscript = document.createElement('noscript')
        noscript.innerHTML = `<img height="1" width="1" src="https://www.facebook.com/tr?id=${config.pixel.id}&ev=PageView&noscript=1"/>`
        document.body.appendChild(noscript)
      }

      if (config.google && config.google.gtag) {
        const gaScript = document.createElement('script')
        gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${config.google.gtag.id}`
        gaScript.async = true

        const gtagScript = document.createElement('script')
        gtagScript.type = 'text/javascript'
        gtagScript.innerHTML = `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.google.gtag.id}');`
        document.body.appendChild(gaScript)
        document.body.appendChild(gtagScript)
      }
    }
  }
}
