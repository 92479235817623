import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export default class BlogCommentModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      id: Joi.string().required(),
      createdAt: Joi.date().required(),

      article: Joi.string().required(),
      name: Joi.string().required(),
      comment: Joi.string().required(),
      ssoUserId: Joi.string().required()
    }
  }

  static fromJson (data) {
    return new BlogCommentModel(data, { stripUnknown: true })
  }
}
