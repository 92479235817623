import promiseRetry from 'promise-retry'

export function retry (func, { minTimeout = 2000, forever = true, factor = 1 } = {}) {
  return promiseRetry(async function (retry, number) {
    try {
      const result = await func()
      return result
    } catch (e) {
      retry(e)
    }
  }, { minTimeout, factor, forever })
}
