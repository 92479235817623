import { LoadableMapModel, RateModel } from 'src/models'
import { ratesDAO } from 'src/daos'

const RATE_LOADED = 'rate/loaded'
const RATE_LOADING = 'rate/loading'
const RATE_RESET = 'rate/reset'

const initialState = () => ({
  table: new LoadableMapModel(RateModel),
  fiatCurrencies: ['USD', 'EUR', 'CNY', 'GBP', 'RUB', 'BTC', 'ETH']
})

export default () => ({
  namespaced: true,
  state () {
    return initialState()
  },
  mutations: {
    [RATE_LOADED]: (state, { from, to, price }) => {
      const model = state.table.get(`${from}:${to}`) || new RateModel({ from, to, price })
      state.table = state.table.put(`${from}:${to}`, model.loaded({ price }))
    },
    [RATE_LOADING]: (state, { from, to, price }) => {
      const model = state.table.get(`${from}:${to}`) || new RateModel({ from, to, price })
      state.table = state.table.put(`${from}:${to}`, model.loading())
    },
    [RATE_RESET]: (state) => {
      Object.assign(state, initialState())
    }
  },
  getters: {
    rate: state => (from, to) => {
      if (from === to) {
        return new RateModel({ from, to, price: '1', isLoading: false, isLoaded: true })
      }
      if (from == null || to == null || from === '' || to === '') {
        return 0
      }
      return state.table.get(`${from}:${to}`) || new RateModel({ from, to })
    }
  },
  actions: {
    async update ({ commit }, { from, to }) {
      commit(RATE_LOADING, { from, to })
      const { price } = await ratesDAO.getRate(from, to)
      commit(RATE_LOADED, { from, to, price })
    },
    async subscribe ({ commit, dispatch }, { from, to }) {
      const priceListener = ({ payload }) => {
        commit(RATE_LOADED, { from: payload.from, to: payload.to, price: payload.price })
      }

      const subscribeListener = () => {
        // dispatch('update', { from, to })
      }

      ratesDAO
        .subscribeMarket(from, to)
        .on('PRICE', priceListener)
        .on('@_SUBSCRIBED', subscribeListener)

      // dispatch('update', { from, to })

      return { priceListener, subscribeListener }
    },
    async unsubscribe (_, { from, to, listener }) {
      const { priceListener, subscribeListener } = listener
      ratesDAO
        .unsubscribeMarket(from, to)
        .removeListener('PRICE', priceListener)
        .removeListener('@_SUBSCRIBED', subscribeListener)
    }
  }
})
