import VueTypes from 'vue-types'
import get from 'lodash/get'
import { pickClass } from 'src/utils'
import Spinner from '@/components/lib/Spinner/Spinner.vue'
import { roistat, Constants } from 'src/analytics'

export default {
  components: {
    Spinner
  },
  props: {
    title: String,
    size: VueTypes.oneOf(['medium', 'small', 'large']).def('medium'),
    theme: VueTypes.oneOf(['dark', 'light', 'bordered', 'transparent']).def('dark'),
    fullWidth: Boolean,
    autoWidth: Boolean,
    loading: Boolean,
    disabled: Boolean
  },
  computed: {
    spinnerTheme () {
      return this.pickClass({
        light: this.theme === 'transparent' || this.theme === 'dark',
        dark: 'light'
      })
    }
  },
  methods: {
    pickClass,
    onClick (e) {
      let title = this.title
      if (!title) {
        // try get content from slot
        title = get(this.$slots.default, '[0].text')
      }

      roistat.sendEvent({
        eventName: Constants.actions.BUTTON_CLICK,
        properties: {
          title: title,
          url: window.location.href,
          timestamp: new Date().toISOString()
        }
      })
      this.$emit('click', e)
    }
  }
}
