/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'about-us/icon_04': {
    width: 74,
    height: 74,
    viewBox: '0 0 74 74',
    data: '<path pid="0" d="M20.364 56.176c10.355 9.029 26.069 7.954 35.098-2.4 9.03-10.356 7.955-26.07-2.4-35.099-10.355-9.03-26.07-7.954-35.098 2.4-9.03 10.355-7.955 26.07 2.4 35.099z" _stroke="#fff" stroke-width=".75" stroke-miterlimit="10"/><g clip-path="url(#clip0)" _stroke="#FDA431" stroke-width=".5" stroke-miterlimit="10"><path pid="1" d="M43.029 20.57l-8.374 22.47 12.184-2.18-3.81-20.29zM43.029 20.569l-16.16 12.846 7.786 9.624 3.89-10.44 4.484-12.03zM33.95 44.93l-3.045 8.172L46.14 42.755 33.95 44.93z"/><path pid="2" d="M30.905 53.101l3.046-8.172-7.787-9.62L30.905 53.1zM34.655 43.042l12.185-2.18-8.294-8.26-3.891 10.44zM26.87 33.417l7.785 9.624 3.89-10.44-11.676.816z"/></g><defs><clipPath id="clip0"><path pid="3" _fill="#fff" transform="rotate(20.439 -18.035 75.557)" d="M0 0h39.972v39.972H0z"/></clipPath></defs>'
  }
})
