import Joi from 'joi'
import AbstractModel from './AbstractModel'

export default class SeoCurrencyModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      name: Joi.string().required(),
      symbol: Joi.string().required(),
      tvSymbol: Joi.string().allow(null, ''),
      isOnTimex: Joi.boolean(),
      rateServiceSymbol: Joi.string().allow(null, ''),
      icon: Joi.object()
    }
  }

  static fromJson (data) {
    return new SeoCurrencyModel({
      ...data
    })
  }
}
