import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export default class ReferralLink extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      id: Joi.string().required(),
      type: Joi.string().required(),
      state: Joi.string().required(),
      refCode: Joi.string().required(),
      title: Joi.string().required(),
      createdAt: Joi.string().required(),
      expireAt: Joi.string().required(),
      shares: Joi.object().keys({
        level0: Joi.number().required(),
        level1: Joi.number().required(),
        level2: Joi.number().required(),
        level3: Joi.number().required()
      }).required()
    }
  }

  static fromJson (data) {
    if (data == null) {
      return null
    }

    return new ReferralLink({ ...data })
  }
}
