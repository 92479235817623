import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export default class BlogArticlesRequest extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      category: Joi.string().allow(null, ''),
      tags: Joi.array().items(
        Joi.string()
      ).allow(null),
      query: Joi.string().allow(null, ''),
      page: Joi.number().default(0),
      size: Joi.number().default(10)
    }
  }
}
