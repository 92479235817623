/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'planet': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2a9.982 9.982 0 0 0-7.979 4H4v.027A9.986 9.986 0 1 0 12 2zm3 2.584A8 8 0 0 1 17.9 17.4 1.993 1.993 0 0 0 16 16h-1v-3a1 1 0 0 0-1-1H9v-2h1a1 1 0 0 0 1-1V7h2a2 2 0 0 0 2-2v-.416zM4.207 10.207L6 12l3 3v1a2 2 0 0 0 2 2v1.932a7.978 7.978 0 0 1-6.793-9.725z" _fill="#000"/>'
  }
})
