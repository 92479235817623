/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<path pid="0" opacity=".8" d="M7.125 1.583a5.541 5.541 0 103.621 9.723l.337.337v1.024l4.75 4.75 1.584-1.584-4.75-4.75h-1.024l-.337-.337a5.53 5.53 0 00-4.181-9.163zm0 1.584a3.958 3.958 0 11-3.958 3.958 3.946 3.946 0 013.958-3.958z" _fill="#111"/>'
  }
})
