/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'about-us/icon_10': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<path pid="0" d="M17.7 5.754a1.273 1.273 0 011.804 0 1.273 1.273 0 01.001 1.806L7.561 19.504a1.273 1.273 0 01-1.805 0 1.273 1.273 0 01-.001-1.806L17.7 5.754zm-2.928-2.928L2.826 14.771a5.424 5.424 0 00.004 7.657c2.098 2.099 5.537 2.125 7.658.004l11.944-11.944a5.424 5.424 0 00-.004-7.658C20.282.73 16.868.73 14.772 2.826z" _fill="#1C1C30" _stroke="#FDA431" stroke-width=".75" stroke-miterlimit="10"/>'
  }
})
