<template>
  <div class="root-modal" :class="{ scrollable: modal.isScrollable }" @click.stop>
    <div class="content">
      <proxy :component="modal.factory()" :pass="modal.data" ref="component"/>
    </div>
  </div>
</template>

<script>
import Proxy from './Proxy.vue'

export default {
  props: {
    modal: Object
  },
  components: {
    Proxy
  },
  methods: {
    handleClose () {
      const form = this.$el.querySelector('form')
      if (form) {
        form.reset()
      } else {
        this.$store.dispatch('modals/close')
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~src/styles/mixins';
@import '~src/styles/variables';

.root-modal {
  @include position(fixed, 0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0.600);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  >.content {
    @include position(absolute, 0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 0 0 auto;
    height: 100%;
    width: 100%;
    >* {
      flex: 0 0 auto;
    }
  }
  @include screen-max-width(600px) {
    >.content {
      padding: 0 12px;
      >* {
        width: 100%;
      }
    }
    &.scrollable {
      overflow-y: scroll;
      >.content {
        >div {
          margin-bottom: 50px;
        }
      }
    }
  }
}
</style>
