/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11 5.93l-1.5 1.5L14.07 12 9.5 16.57l1.5 1.5L17.07 12 11 5.93z" _fill="#000"/>'
  }
})
