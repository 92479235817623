/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'usd': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21 37.1c8.892 0 16.1-7.208 16.1-16.1 0-8.892-7.208-16.1-16.1-16.1C12.11 4.9 4.9 12.108 4.9 21c0 8.892 7.209 16.1 16.1 16.1zm0 1.4c9.666 0 17.5-7.835 17.5-17.5S30.667 3.5 21 3.5C11.337 3.5 3.5 11.335 3.5 21S11.337 38.5 21 38.5z" _fill="#C4C4CF"/><path pid="1" opacity=".8" d="M23.475 24.785c0-.595-.187-1.086-.562-1.472-.374-.392-.997-.743-1.87-1.051-.874-.316-1.553-.607-2.038-.873-1.615-.876-2.422-2.173-2.422-3.89 0-1.164.35-2.121 1.05-2.871.7-.75 1.65-1.196 2.848-1.336V11h1.663v2.313c1.206.176 2.138.694 2.796 1.557.659.855.988 1.97.988 3.344h-2.516c0-.884-.197-1.578-.592-2.083-.388-.511-.918-.767-1.59-.767-.666 0-1.186.182-1.56.547-.374.364-.561.886-.561 1.566 0 .61.183 1.101.55 1.473.375.364 1.006.711 1.893 1.04.887.33 1.583.635 2.09.915.505.28.931.603 1.278.968.346.357.613.77.8 1.24.187.47.28 1.02.28 1.652 0 1.184-.36 2.145-1.08 2.88-.714.737-1.712 1.171-2.994 1.305V31h-1.653v-2.04c-1.372-.154-2.429-.652-3.17-1.493C16.368 26.626 16 25.51 16 24.123h2.526c0 .883.218 1.567.655 2.05.443.484 1.067.726 1.87.726.79 0 1.39-.193 1.799-.578.416-.386.624-.898.624-1.536z" _fill="#111"/>'
  }
})
