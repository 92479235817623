/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path d="M1.5 3.75V5.25H16.5V3.75H1.5ZM1.5 8.25V9.75H16.5V8.25H1.5ZM1.5 12.75V14.25H16.5V12.75H1.5Z" fill="white" fill-opacity="0.8"/>'
  }
})
