import EventEmitter from 'events'
import uniqid from 'uniqid'

export default class SocketClient extends EventEmitter {
  constructor ({ url }) {
    super()
    this.setMaxListeners(100)
    this.url = url
    this.queue = []
  }

  start () {
    // eslint-disable-next-line
    console.log('[WebSocket] Trying to connect...')
    this.socket = new WebSocket(this.url)
    this.socket.onopen = this.handleOpen.bind(this)
    this.socket.onmessage = this.handleMessage.bind(this)
    this.socket.onclose = this.handleClose.bind(this)
    return this
  }

  prune () {
    this.queue = []
    this.removeAllListeners()
    return this
  }

  ensure (message) {
    const runnable = () => {
      this.socket.send(JSON.stringify(message))
    }
    if (this.isConnected) {
      runnable()
      return {
        message,
        close: () => { return false }
      }
    } else {
      this.queue.push(runnable)
      return {
        message,
        close: () => {
          this.queue = this.queue.filter(r => r !== runnable)
          return runnable
        }
      }
    }
  }

  handleOpen () {
    this.isConnected = true
    this.sessionId = uniqid()
    for (const runnable of this.queue) {
      runnable()
    }
    this.queue = []
    setImmediate(() => {
      this.emit('open', {
        sessionId: this.sessionId
      })
    })
  }

  handleError (e) {
    setImmediate(() => {
      this.emit('error', e)
    })
  }

  handleMessage (message) {
    const data = JSON.parse(message.data)
    setImmediate(() => {
      this.emit('message', data)
    })
  }

  handleClose () {
    setImmediate(() => {
      this.emit('close', {
        sessionId: this.sessionId
      })
    })
    this.sessionId = null
    this.isConnected = false
    this.socket = null
    setTimeout(() => {
      this.start()
      this.emit('reconnect')
    }, 2000)
  }

  send (msg) {
    this.ensure(msg)
    return this
  }
}
