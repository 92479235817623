import {
  ShortGameArticleModel,
  LoadableModel
} from 'src/models'
import { getPublicBackend } from 'src/remotes'

export const GAME_MEMBERS_LOADED = 'gameMembers/loaded'
export const GAME_PARTNERS_LOADED = 'gamePartners/loaded'

export const GAME_ARTICLES_LOADING = 'gameArticles/loading'
export const GAME_ARTICLES_LOADED = 'gameArticles/loaded'

export default () => ({
  namespaced: true,
  state: {
    gameMembers: null,
    gamePartners: null,
    gameArticles: new LoadableModel(ShortGameArticleModel)
  },

  mutations: {
    [GAME_MEMBERS_LOADED]: (state, data) => {
      state.gameMembers = data
    },
    [GAME_PARTNERS_LOADED]: (state, data) => {
      state.gamePartners = data
    },
    [GAME_ARTICLES_LOADED]: (state, data) => {
      state.gameArticles = new LoadableModel(ShortGameArticleModel, {
        isLoaded: false,
        isLoading: true,
        value: ShortGameArticleModel.fromJson(data)
      })
    }
  },
  getters: {
    gameMembers: state => state.gameMembers,
    gamePartners: state => state.gamePartners,
    gameArticles: state => state.gameArticles
  },
  actions: {
    async loadGameMembers ({ state, commit }, game) {
      if (!state.gameMembers) {
        const { data } = await getPublicBackend().get(`gaming/game/${game}/members`)
        commit(GAME_MEMBERS_LOADED, data)
      }
    },
    async loadGamePartners ({ state, commit }, game) {
      if (!state.gameMembers) {
        const { data } = await getPublicBackend().get(`gaming/game/${game}/partners`)
        commit(GAME_PARTNERS_LOADED, data)
      }
    },
    async loadGameArticles ({ state, commit, dispatch }, game) {
      const { data } = await getPublicBackend().get(`gaming/game/${game}/articles`)
      commit(GAME_ARTICLES_LOADED, data)
    }
  }
})
