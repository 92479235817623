import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export default class PlasmaTradeModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      id: Joi.number().required(),
      price: Joi.string().required(),
      quantity: Joi.string().required(),
      direction: Joi.string().allow('BUY', 'SELL').required(),
      timestamp: Joi.string().required()
    }
  }

  static fromJs (data) {
    return new PlasmaTradeModel({
      ...data
    }, { stripUnknown: true })
  }
}
