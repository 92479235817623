/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-down': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 10l6 6 6-6H6z" _fill="#000"/>'
  }
})
