/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 6.93L5.93 13l1.5 1.5L12 9.93l4.57 4.57 1.5-1.5L12 6.93z" _fill="#000"/>'
  }
})
