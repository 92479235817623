import get from 'lodash/get'
import set from 'lodash/set'
import { getPublicBackend } from 'src/remotes'
import publicBackendService from 'src/publicBackendService'

export const SOCIALS_LOADED = 'socials/loaded'
export const PAPERS_LOADED = 'papers/loaded'
export const PARTNERS_LOADED = 'partners/loaded'
export const MEMBERS_LOADED = 'members/loaded'
export const CONTACTS_LOADED = 'contacts/loaded'
export const SECTIONS_LOADED = 'sections/loaded'
export const TEMPLATES_LOADED = 'templates/loaded'
export const NEWS_LOADED = 'news/loaded'
export const OTC_CONTACTS_LOADED = 'otcContacts/loaded'
export const COMPANY_CONTACTS_LOADED = 'companyContactsLoaded/loaded'
export const FOOTER_COINS_LOADED = 'footerCoins/loaded'
export const EXTERNAL_LINKS_LOADED = 'externalLinks/loaded'
export const WEB_PAGES_LOADED = 'webPages/loaded'
export const OUR_PRODUCTS_LOADED = 'ourProducts/loaded'
export const CHECK_SERVER_PREFETCH = 'public/checkServerPrefetch'

export default ({ initialData }) => {
  const {
    sections,
    templates,
    partners,
    members,
    socials,
    papers,
    contacts,
    otcContacts,
    companyContacts,
    externalLinks,
    webPages,
    ourProducts,
    footerCoins
  } = (initialData || {})
  return {
    namespaced: true,
    state: {
      socials: socials || null,
      papers: papers || null,
      partners: partners || null,
      members: members || null,
      contacts: contacts || null,
      webPages: webPages || null,
      ourProducts: ourProducts || null,
      sections: sections == null
        ? null
        : sections.reduce((result, e) => {
          result[e.key] = e
          return result
        }, {}),
      templates: templates == null
        ? null
        : templates.reduce((result, e) => {
          result[e.key] = e
          return result
        }, {}),
      otcContacts: otcContacts || null,
      companyContacts: companyContacts || null,
      footerCoins: footerCoins || null,
      externalLinks: externalLinks == null
        ? null
        : externalLinks.reduce((result, e) => {
          set(result, [e.page, e.key], e)
          return result
        }, {}),
      news: null,
      serverPrefetchExecuted: false
    },
    mutations: {
      [NEWS_LOADED]: (state, { news }) => {
        state.news = news
      },
      [WEB_PAGES_LOADED]: (state, { webPages }) => {
        state.webPages = webPages
      },
      [OUR_PRODUCTS_LOADED]: (state, { ourProducts }) => {
        state.ourProducts = ourProducts
      },
      [SOCIALS_LOADED]: (state, { socials }) => {
        state.socials = socials
      },
      [PAPERS_LOADED]: (state, { papers }) => {
        state.papers = papers
      },
      [PARTNERS_LOADED]: (state, { partners }) => {
        state.partners = partners
      },
      [MEMBERS_LOADED]: (state, { members }) => {
        state.members = members
      },
      [CONTACTS_LOADED]: (state, { contacts }) => {
        state.contacts = contacts
      },
      [SECTIONS_LOADED]: (state, { sections }) => {
        state.sections = sections.reduce((result, e) => {
          result[e.key] = e
          return result
        }, {})
      },
      [TEMPLATES_LOADED]: (state, { templates }) => {
        state.templates = templates.reduce((result, e) => {
          result[e.key] = e
          return result
        }, {})
      },
      [OTC_CONTACTS_LOADED]: (state, { otcContacts }) => {
        state.otcContacts = otcContacts
      },
      [FOOTER_COINS_LOADED]: (state, { footerCoins }) => {
        state.footerCoins = footerCoins
      },
      [COMPANY_CONTACTS_LOADED]: (state, { companyContacts }) => {
        state.companyContacts = companyContacts
      },
      [EXTERNAL_LINKS_LOADED]: (state, { externalLinks }) => {
        state.externalLinks = externalLinks.reduce((result, e) => {
          set(result, [e.page, e.key], e)
          return result
        }, {})
      },
      [CHECK_SERVER_PREFETCH]: (state) => {
        state.serverPrefetchExecuted = !state.serverPrefetchExecuted
      }
    },
    getters: {
      footerCoins: state => state.footerCoins,
      getResourceString: state => (group, key, language) => {
        const collection = state[group]
        if (collection == null || key == null) {
          return null
        }
        if (key in collection) {
          const section = collection[key]
          if (!section) {
            return key
          }
          if (!section.i18n) {
            return section.title != null
              ? section.title
              : key
          }
          const i18n = section.i18n[language]
          if (!i18n || !i18n.active || !i18n.overrides || i18n.overrides == null) {
            return section.title != null
              ? section.title
              : key
          }
          return i18n.overrides.title != null
            ? i18n.overrides.title
            : null
        }
      },
      getExternalLinkObject: state => (page, key) => {
        return get(state.externalLinks, [page, key])
      }
    },
    actions: {
      async loadExternalLinks ({ state, commit }) {
        if (!state.externalLinks) {
          const { data } = await publicBackendService.getExternalLinks()
          commit(EXTERNAL_LINKS_LOADED, data)
        }
      },
      async loadCompanyContancts ({ state, commit }) {
        if (!state.companyContacts) {
          const { data } = await publicBackendService.getCompanyContacts()
          commit(COMPANY_CONTACTS_LOADED, data)
        }
      },
      async loadOtcContancts ({ state, commit }) {
        if (!state.otcContacts) {
          const { data } = await publicBackendService.getOtcContacts()
          commit(OTC_CONTACTS_LOADED, data)
        }
      },
      async loadSocials ({ state, commit }) {
        if (!state.socials) {
          const { data } = await publicBackendService.getSocials()
          commit(SOCIALS_LOADED, data)
        }
      },
      async loadWebPages ({ state, commit }) {
        if (!state.webPages) {
          const { data } = await publicBackendService.getWebPages()
          commit(WEB_PAGES_LOADED, { webPages: data })
        }
      },
      async loadOurProducts ({ state, commit }) {
        if (!state.ourProducts) {
          const { data } = await publicBackendService.getOurProducts()
          commit(OUR_PRODUCTS_LOADED, data)
        }
      },
      async loadPapers ({ state, commit }) {
        if (!state.papers) {
          const { data } = await publicBackendService.getPapers()
          commit(PAPERS_LOADED, data)
        }
      },
      async loadPartners ({ state, commit }) {
        if (!state.partners) {
          const { data } = await publicBackendService.getPartners()
          commit(PARTNERS_LOADED, data)
        }
      },
      async loadMembers ({ state, commit }) {
        if (!state.members) {
          const { data } = await publicBackendService.getMembers()
          commit(MEMBERS_LOADED, data)
        }
      },
      async loadContacts ({ state, commit }) {
        if (!state.contacts) {
          const { data } = await publicBackendService.getContacts()
          commit(CONTACTS_LOADED, data)
        }
      },
      async loadSections ({ state, commit }) {
        if (!state.sections) {
          const { data } = await publicBackendService.getSections()
          commit(SECTIONS_LOADED, data)
        }
      },
      async loadTemplates ({ state, commit }) {
        if (!state.templates) {
          const { data } = await publicBackendService.getTemplates()
          commit(TEMPLATES_LOADED, data)
        }
      },
      async loadNews ({ state, commit }, { locale }) {
        if (!state.news) {
          const { data } = await publicBackendService.getNews({ locale })
          commit(NEWS_LOADED, data)
        }
      },
      async sendMessage (context, { name, type, contact, message }) {
        const response = await getPublicBackend().post('/message', {
          name,
          type,
          contact,
          message
        })
        return response
      },
      async loadFooterCoins ({ state, commit }) {
        if (!state.footerCoins) {
          const footerCoins = await publicBackendService.getFooterCoins()
          commit(FOOTER_COINS_LOADED, { footerCoins })
        }
      },
      checkServerPrefetch ({ state, commit }) {
        commit(CHECK_SERVER_PREFETCH)
      }
    }
  }
}
