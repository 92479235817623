import Joi from 'joi'
import { AbstractModel } from 'src/models/core'
import BlogCommentModel from './BlogCommentModel'

export default class BlogArticleCommentsResponse extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      items: Joi.array().items(Joi.object().instance(BlogCommentModel)).optional(),
      page: Joi.number().default(0),
      count: Joi.number().default(0),
      totalPages: Joi.number().default(0)
    }
  }

  static fromJson (data) {
    return new BlogArticleCommentsResponse({
      items: data.items.map(BlogCommentModel.fromJson),
      page: data.page,
      totalPages: data.totalPages,
      count: data.count
    })
  }
}
