import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export const schemaFactory = () => Joi.object({
  min: Joi.number(),
  max: Joi.number().allow(null),
  takerFee: Joi.number(),
  makerFee: Joi.number()
})

export default class FeesModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      min: Joi.number(),
      max: Joi.number().allow(null),
      takerFee: Joi.number(),
      makerFee: Joi.number()
    }
  }

  static fromJson (data) {
    return new FeesModel({
      ...data
    })
  }
}
