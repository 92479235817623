export const SET_REFCODE = 'set/refcode'
export const SET_STATE = 'set/state'
export const CLEAR = 'routes/clear'

export default () => ({
  namespaced: true,
  state: () => ({
    refcode: null,
    state: null
  }),
  getters: {
    refcode: state => state.refcode
  },
  mutations: {
    [SET_REFCODE] (state, { refcode }) {
      state.refcode = refcode
    },
    [SET_STATE] (state, { routeState }) {
      state.state = routeState
    },
    [CLEAR] (state) {
      state.refcode = state.state = null
    }
  },
  actions: {
    setRefcode ({ commit }, { refcode }) {
      commit(SET_REFCODE, { refcode })
    },
    setState ({ commit }, { routeState }) {
      commit(SET_STATE, { routeState })
    },
    clear (commit) {
      commit(CLEAR)
    }
  }
})
