import { PROFILE } from 'src/remotes'

export default () => ({
  namespaced: true,
  actions: {
    async saveEmail ({ dispatch }, { email, type }) {
      await PROFILE.post('/public/email', { email, type })
    }
  }
})
