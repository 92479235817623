/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'about-us/icon_01': {
    width: 104,
    height: 104,
    viewBox: '0 0 104 104',
    data: '<path pid="0" d="M28.33 79.492c15.095 13.162 38 11.595 51.163-3.499 13.161-15.094 11.595-38-3.5-51.162-15.094-13.161-38-11.595-51.161 3.5-13.162 15.094-11.595 38 3.499 51.161z" _stroke="#fff" stroke-width=".75" stroke-miterlimit="10"/><path pid="1" d="M64.664 55.55a9.182 9.182 0 00-2.555-2.264 8.522 8.522 0 002.643-1.032 6.813 6.813 0 001.896-2.557 6.295 6.295 0 00-.377-5.915 11.985 11.985 0 00-5.784-4.059l1.9-5.819-3.601-1.176-1.88 5.755-2.74-.895 1.878-5.755-3.593-1.173-1.88 5.755-7.083-2.313-1.218 3.73 2.081.678a3.134 3.134 0 011.828 1.197c.273.618.267.894-.037 1.825l-4.851 14.86a1.53 1.53 0 01-.716.955c-.396.187-.725.154-1.443-.08l-2.157-.705-2.147 4.072 7.083 2.313-1.874 5.739 3.6 1.175 1.875-5.743 2.735.893-1.873 5.739 3.6 1.175 1.904-5.834c.947.25 1.837.465 2.366.562 2.026.47 4.136.43 6.142-.112 2.663-.801 3.922-3.009 4.75-5.376a6.025 6.025 0 00-.472-5.615v0zM50.58 48.58l2.262-6.928s1.177.273 2.298.657c1.227.374 2.387.94 3.436 1.679.605.37 1.074.925 1.335 1.584a3.26 3.26 0 01.116 2.065 2.954 2.954 0 01-2.441 2.229 9.246 9.246 0 01-4.013-.307c-1.327-.38-2.993-.98-2.993-.98zm4.715 12.866c-1.471.151-2.959.03-4.387-.357-1.638-.4-4.008-1.234-4.008-1.234l2.514-7.701s2.326.625 4.07 1.329a12.149 12.149 0 013.656 2.197 3.349 3.349 0 01.914 3.47 3.337 3.337 0 01-2.759 2.296v0z" _stroke="#FDA431" stroke-width=".75" stroke-miterlimit="10"/>'
  }
})
