import BigNumber from 'bignumber.js'

export const UNLIMITED = new BigNumber(2).pow(256).minus(1)
export const ZERO = new BigNumber(0)

export function isUnlimited (value) {
  return UNLIMITED.isEqualTo(value)
}

export function isZero (value) {
  return ZERO.isEqualTo(value)
}

export function pow10 (exponent) {
  return new BigNumber(10).pow(exponent)
}

export function min (bn1, bn2) {
  return bn1.lt(bn2)
    ? bn1
    : bn2
}

export function max (bn1, bn2) {
  return bn1.gt(bn2)
    ? bn1
    : bn2
}

export function getScale (value) {
  let v = new BigNumber(value)

  if (v.gte(1)) {
    return 0
  }

  let count = 0
  do {
    count++
    v = v.times(10)
  } while (!v.gte(1))

  return count
}
