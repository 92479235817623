import EventEmitter from 'events'
import uniqid from 'uniqid'

export default class RatesChannel extends EventEmitter {
  constructor (client, { from, to }) {
    super()
    this.client = client
    this.from = from
    this.to = to
    this.subscribeRequestId = uniqid()

    const handleSubscribed = m => {
      if (m.type === 'subscribed' && m.requestId === this.subscribeRequestId) {
        client.removeListener('message', handleSubscribed)
        this.subscriptionId = m.subscriptionId
        this.emit('subscribed')
      }
    }

    const handleMessage = m => {
      if (m.type === 'update' && m.subscriptionId === this.subscriptionId) {
        this.emit('message', { payload: m.payload })
      }
    }

    client.on('message', handleSubscribed)
    client.on('message', handleMessage)

    this.client.send({
      type: 'subscribe',
      from,
      to,
      requestId: this.subscribeRequestId
    })
  }

  close () {
    try {
      this.client.send({
        type: 'unsubscribe',
        subscriptionId: this.subscriptionId,
        requestId: uniqid()
      })
      this.subscriptionId = null
      this.client = null
    } catch (e) {
      // ignore
    }
  }
}
