/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'layout': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M2.5 2.5a.833.833 0 00-.833.833v13.334a.833.833 0 00.833.833h3.334v-15H2.5zm5 0v6.667h10.834V3.333A.833.833 0 0017.5 2.5h-10zm0 8.334V17.5h5v-6.666h-5zm6.667 0V17.5H17.5a.833.833 0 00.834-.833v-5.834h-4.167z" _fill="#fff" fill-opacity=".7"/>'
  }
})
