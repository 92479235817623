import Joi from 'joi'
import { AbstractModel } from 'src/models/core'

export default class BlogCategoryModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      id: Joi.string().required(),
      name: Joi.string().required(),
      showOnLanding: Joi.boolean()
    }
  }

  static fromJson (data) {
    return new BlogCategoryModel(data, { stripUnknown: true })
  }
}
