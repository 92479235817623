/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'linkedin': {
    width: 16,
    height: 15,
    viewBox: '0 0 16 15',
    data: '<g opacity=".8" _fill="#111"><path pid="0" d="M3.634 15V4.878H.204V15h3.43zM1.919 3.497c1.192 0 1.942-.78 1.942-1.749C3.838.758 3.11 0 1.94 0 .773 0 0 .757 0 1.748c0 .97.75 1.749 1.896 1.749h.023zM5.53 15h3.43V9.343c0-.3.022-.601.113-.824.25-.601.818-1.225 1.76-1.225 1.238 0 1.738.924 1.738 2.294V15H16V9.198c0-3.107-1.692-4.554-3.952-4.554-1.85 0-2.668 1.013-3.111 1.703h.023v-1.47H5.53c.046.947 0 10.123 0 10.123z"/></g>'
  }
})
