// import 'material-design-icons-iconfont/dist/material-design-icons.css'
// import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import MessageFormat from 'string-format'
import InlineSvg from 'vue-inline-svg'
import SvgIcon from 'vue-svgicon'
import NoSSR from 'vue-no-ssr'
import { mapState, mapGetters } from 'vuex'
import publicBackendService from './publicBackendService'
import exchangeBackendService from './exchangeBackendService'

import 'src/icons'

import App from './App.vue'
import createRouter from './router'
import createStore from './store'

Vue.config.productionTip = false

Vue.use(Vuelidate)
Vue.use(SvgIcon)
Vue.component('inline-svg', InlineSvg)
Vue.component('no-ssr', NoSSR)

// add format method to String.prototype
MessageFormat.extend(String.prototype, {})

Vue.mixin({
  computed: {
    ...mapState({
      sections: state => state.public.sections,
      templates: state => state.public.templates
    }),
    ...mapGetters({
      config: 'config/config',
      language: 'language/language'
    })
  },
  methods: {
    i18n (object, { field }) {
      if (!object || !object.i18n) {
        return `${object}.${field}`
      }

      if (!object.i18n[this.language] || !object.i18n[this.language].active) {
        return object[field]
      }

      return object.i18n[this.language].overrides[field]
    },
    i18nTemplate (template, ...params) {
      return this.i18n(this.templates[template], { field: 'title' }).format(...params.map(function (param) {
        return typeof param === 'string' ? this.i18n(this.sections[param], { field: 'title' }) : param.value
      }.bind(this)))
    },
    i18nSection (key, keyIfNotExist) {
      let result = this.i18n(this.sections[key], { field: 'title' })
      if (result === 'undefined.title' && keyIfNotExist) {
        result = this.i18n(this.sections[keyIfNotExist], { field: 'title' })
      }
      if (result === 'undefined.title') {
        result = ''
      }
      return result
    },
    openPrivateUrl (subPath) {
      const originURL = new URL(window.location.href)
      const searchParams = originURL.searchParams.toString()
      if (searchParams) {
        document.location.href = `${window.location.origin}/${subPath}?${searchParams}`
      } else {
        document.location.href = `${window.location.origin}/${subPath}`
      }
    },
    getLanguageUrlPathPrefix () {
      if (this.language === 'en') {
        return ''
      }
      return `/${this.language}`
    },
    getLocalizedUrlForCurrntPage (lang) {
      const currentLanguage = this.language

      const location = process.client
        ? window.location.href
        : `${this.config.self.url}${this.$route.fullPath}`

      if (currentLanguage === lang) {
        return location
      }

      const newLocation = this._getLocalizedUrlForCurrentPage(lang)
      return newLocation
    },
    changeLanguage (lang) {
      const currentLanguage = this.language

      if (currentLanguage === lang) {
        return
      }

      const newLocation = this._getLocalizedUrlForCurrentPage(lang)
      window.location.href = newLocation
    },
    _getLocalizedUrlForCurrentPage (lang) {
      const currentLanguage = this.language

      let newLocation = ''

      const location = process.client
        ? window.location.href
        : `${this.config.self.url}${this.$route.fullPath}`

      const hostName = process.client
        ? window.location.hostname
        : this.config.self.url

      if (lang === 'en') {
        newLocation = location.replace(
          `${hostName}/${currentLanguage}`,
          hostName
        )
      } else {
        if (currentLanguage === 'en') {
          newLocation = location.replace(
            `${hostName}`,
            `${hostName}/${lang}`
          )
        } else {
          newLocation = location.replace(
            `${hostName}/${currentLanguage}`,
            `${hostName}/${lang}`
          )
        }
      }
      return newLocation
    }
  }
})

export default () => {
  const store = createStore()
  const router = createRouter({ store })

  publicBackendService.init()
  exchangeBackendService.init()

  return new Vue({
    router,
    store,
    render: h => h(App)
  })
}
