/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'convert': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M15 1.667L11.667 5h2.5v5a.82.82 0 01-.833.833H7.5V12.5h5.834c1.37 0 2.5-1.13 2.5-2.5V5h2.5L15 1.667zM6.667 7.5c-1.37 0-2.5 1.13-2.5 2.5v5h-2.5L5 18.333 8.334 15h-2.5v-5a.82.82 0 01.833-.833H12.5V7.5H6.667z" _fill="#fff" fill-opacity=".7"/>'
  }
})
