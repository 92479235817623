import Joi from 'joi'
import { AbstractModel } from 'src/models/core'
import FeesModel from './FeesModel'

export default class MarketFeeModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      symbol: Joi.string().required(),
      fees: Joi.array().items(
        Joi.object().instance(FeesModel)
      ).required()
    }
  }

  static fromJson (data) {
    return new MarketFeeModel({
      symbol: data.symbol,
      fees: data.fees.map(FeesModel.fromJson)
    })
  }
}
