import AbstractSocketDAO from './AbstractSocketDAO'
import RatesChannel from './RatesChannel'

class RatesDAO extends AbstractSocketDAO {
  constructor () {
    super()
    this.ratesSubscriptions = {}
  }

  connect (client, api, options) {
    if (this.isConnected) {
      this.disconnect()
    }
    this.client = client
    this.api = api

    for (const subscription of Object.values(this.ratesSubscriptions)) {
      const { from, to, counter } = subscription
      const channel = new RatesChannel(this.client, { from, to }, null)
        .on('message', message => this.handleMessage(message))
        .on('subscribed', () => this.handleSubscribed({ from, to }))
      subscription.channel = channel
      // eslint-disable-next-line
      console.info(`[RatesDao] Subscription to [from,to] = [${from},${to}] pair restored with ${counter} watcher(s) `)
    }
  }

  subscribeMarket (from, to) {
    let subscription = this.ratesSubscriptions[`${from}:${to}`]
    if (!subscription) {
      const channel = new RatesChannel(this.client, { from, to }, null)
        .on('message', message => this.handleMessage(message))
        .on('subscribed', () => this.handleSubscribed({ from, to }))
      subscription = this.ratesSubscriptions[`${from}:${to}`] = {
        from,
        to,
        channel,
        counter: 1
      }
    } else {
      subscription.counter++
    }
    return this
  }

  unsubscribeMarket (from, to) {
    const subscription = this.ratesSubscriptions[`${from}:${to}`]
    if (!subscription) {
      // eslint-disable-next-line
      console.warn('[CurrencyDAO] Attempt to unsubscribe on invalid address')
    } else {
      subscription.counter--
      if (subscription.channel) {
        subscription.channel.removeAllListeners()
        subscription.channel.close()
        subscription.channel = null
      }
      if (subscription.counter === 0) {
        delete this.ratesSubscriptions[`${from}:${to}`]
      }
    }
    return this
  }

  // async getRate (from, to) {
  //   const { data } = await this.api.get(`/rates`, {
  //     params: { from, to }
  //   })
  //   const { price } = data
  //   return { price }
  // }

  handleMessage ({ payload }) {
    setImmediate(() => {
      this.emit('PRICE', { payload })
    })
  }

  handleSubscribed ({ from, to }) {
    setImmediate(() => {
      this.emit('@_SUBSCRIBED', { from, to })
    })
  }

  disconnect () {
    if (this.isConnected) {
      // Stop subscriptions but leave metadata
      for (const subscription of Object.values(this.ratesSubscriptions)) {
        subscription.channel.removeAllListeners()
        subscription.channel.close()
        subscription.channel = null
      }

      this.client = null
      this.api = null
    }
  }
}

export const ratesDAO = new RatesDAO()
export default RatesDAO
