/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signin': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17 12l-4-4v3H3v2h10v3l4-4zM8 7h2V5h9v14h-9v-2H8v2a2.015 2.015 0 002 2h9a2.015 2.015 0 002-2V5a2.015 2.015 0 00-2-2h-9a2.015 2.015 0 00-2 2v2z" _fill="#7360C5"/>'
  }
})
