/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 14l-6-6-6 6h12z" _fill="#000"/>'
  }
})
