import axios from 'axios'
import { SimpleCache } from 'src/cache'
import configProvider from 'src/configProvider'

class PublicBackendService {
  constructor () {
    this.isClientProcess = process.client
    this.client = null

    if (!this.isClientProcess) {
      this.cache = new SimpleCache()
    }
  }

  init () {
    if (this.isClientProcess) {
      this.client = axios.create(configProvider.getConfig().publicService.endpoint)
    } else {
      this.client = axios.create(global.__SERVER_CONFIG__.publicService.endpoint)
    }
  }

  async getExternalLinks () {
    if (this.isClientProcess) {
      return this.client.get('/external-links')
    } else {
      return this.cache.getOrStore('external-links', async () => {
        const { data } = await this.client.get('/external-links')
        return { data }
      })
    }
  }

  async getCompanyContacts () {
    if (this.isClientProcess) {
      return this.client.get('/company-contacts')
    } else {
      return this.cache.getOrStore('company-contacts', async () => {
        const { data } = await this.client.get('/company-contacts')
        return { data }
      })
    }
  }

  async getOtcContacts () {
    if (this.isClientProcess) {
      return this.client.get('/otcContacts')
    } else {
      return this.cache.getOrStore('otcContacts', async () => {
        const { data } = await this.client.get('/otcContacts')
        return { data }
      })
    }
  }

  async getSocials () {
    if (this.isClientProcess) {
      return this.client.get('/socials')
    } else {
      return this.cache.getOrStore('socials', async () => {
        const { data } = await this.client.get('/socials')
        return { data }
      })
    }
  }

  async getWebPages () {
    if (this.isClientProcess) {
      return this.client.get('/web-pages')
    } else {
      return this.cache.getOrStore('web-pages', async () => {
        const { data } = await this.client.get('/web-pages', {
          params: {
            scope: 'TIMEX'
          }
        })
        return { data }
      })
    }
  }

  async getOurProducts () {
    if (this.isClientProcess) {
      return this.client.get('/our-products')
    } else {
      return this.cache.getOrStore('our-products', async () => {
        const { data } = await this.client.get('/our-products')
        return { data }
      })
    }
  }

  async getPapers () {
    if (this.isClientProcess) {
      return this.client.get('/papers')
    } else {
      return this.cache.getOrStore('papers', async () => {
        const { data } = await this.client.get('/papers')
        return { data }
      })
    }
  }

  async getPartners () {
    if (this.isClientProcess) {
      return this.client.get('/partners')
    } else {
      return this.cache.getOrStore('partners', async () => {
        const { data } = await this.client.get('/partners')
        return { data }
      })
    }
  }

  async getMembers () {
    if (this.isClientProcess) {
      return this.client.get('/members')
    } else {
      return this.cache.getOrStore('members', async () => {
        const { data } = await this.client.get('/members')
        return { data }
      })
    }
  }

  async getContacts () {
    if (this.isClientProcess) {
      return this.client.get('/contacts')
    } else {
      return this.cache.getOrStore('contacts', async () => {
        const { data } = await this.client.get('/contacts')
        return { data }
      })
    }
  }

  async getSections () {
    if (this.isClientProcess) {
      return this.client.get('/sections')
    } else {
      return this.cache.getOrStore('sections', async () => {
        const { data } = await this.client.get('/sections')
        return { data }
      })
    }
  }

  async getTemplates () {
    if (this.isClientProcess) {
      return this.client.get('/templates')
    } else {
      return this.cache.getOrStore('templates', async () => {
        const { data } = await this.client.get('/templates')
        return { data }
      })
    }
  }

  async getNews ({ locale }) {
    if (this.isClientProcess) {
      return this.client.get('/landing-news', {
        params: {
          locale
        }
      })
    } else {
      return this.cache.getOrStore('landing-news', async () => {
        const { data } = await this.client.get('/landing-news', {
          params: {
            locale
          }
        })
        return { data }
      })
    }
  }

  async getFooterCoins () {
    if (this.isClientProcess) {
      return this.client.get('/footer-coins')
    } else {
      return this.cache.getOrStore('footer-coins', async () => {
        const footerCoins = await this.client.get('/footer-coins')
        return footerCoins
      })
    }
  }

  async getSeoCurrencies () {
    return this.client.get('/seo-currencies')
  }
}

const instance = new PublicBackendService()
export default instance
