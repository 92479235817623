/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'card': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M2.5 3.333A1.667 1.667 0 00.833 5v10A1.667 1.667 0 002.5 16.667h15A1.667 1.667 0 0019.166 15V5A1.667 1.667 0 0017.5 3.333h-15zm4.166 3.334A1.667 1.667 0 115 8.333a1.638 1.638 0 011.666-1.666zm5 .833h5v1.667h-5V7.5zm-5 3.333c1.821 0 3.334.758 3.334 1.848v.652H3.333v-.652c0-1.09 1.513-1.848 3.333-1.848zm5 0h5V12.5h-5v-1.667z" _fill="#fff" fill-opacity=".7"/>'
  }
})
