/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkmark': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.293 5.293L9 16.586l-4.293-4.293-1.414 1.414L9 19.414 21.707 6.707l-1.414-1.414z" _fill="#000"/>'
  }
})
