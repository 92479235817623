import uniqid from 'uniqid'

export const MODALS_OPEN = 'modals/open'
export const MODALS_CLOSE = 'modals/close'
export const MODALS_REPLACE = 'modals/replace'
export const MODALS_REMOVE = 'modals/remove'

export default () => ({
  namespaced: true,
  state: {
    stack: []
  },
  mutations: {
    [MODALS_OPEN]: (state, modal) => {
      state.stack.push({
        id: uniqid(),
        modal
      })
    },
    [MODALS_REMOVE]: (state, { tags }) => {
      state.stack = state.stack.filter(entry => {
        const modalTags = entry.modal.tags
        if (modalTags) {
          for (const tag of tags) {
            if (modalTags.indexOf(tag) < 0) {
              return true
            }
          }
          return false
        }
        return true
      })
    },
    [MODALS_CLOSE]: (state) => {
      state.stack.pop()
    },
    [MODALS_REPLACE]: (state, modal) => {
      const stack = state.stack
      stack.splice(stack.length - 1, 1, {
        id: uniqid(),
        modal
      })
      state.stack = [
        ...stack
      ]
    }
  },
  actions: {
    open ({ commit }, modal) {
      commit(MODALS_OPEN, modal)
    },
    close ({ commit }) {
      commit(MODALS_CLOSE)
    },
    remove ({ commit }, { tags }) {
      commit(MODALS_REMOVE, { tags })
    },
    replace ({ commit }, modal) {
      commit(MODALS_REPLACE, modal)
    }
  }
})
