import Vue from 'vue'
import VueRouter from 'vue-router'
import isEmpty from 'lodash/isEmpty'
import Meta from 'vue-meta'
import configProvider from 'src/configProvider'
import { roistat, Constants } from 'src/analytics'

const MainPage = () => import('@/pages/lib/MainPage/MainPage.vue')
const AboutPage = () => import('@/pages/lib/AboutPage/AboutPage.vue')
const TechnologyPage = () => import('@/pages/lib/TechnologyPage/TechnologyPage.vue')
const CurrencyPageWrapper = () => import('@/pages/lib/CurrencyPage/CurrencyPageWrapper/CurrencyPageWrapper.vue')
const CoinPageWrapper = () => import('@/pages/lib/CoinsPages/CoinPageWrapper/CoinPageWrapper.vue')
const BlogWrapper = () => import('@/pages/lib/BlogPages/BlogMainWrapper/BlogMainWrapper.vue')
const ArticleWrapper = () => import('@/pages/lib/BlogPages/BlogArticleWrapper/BlogArticleWrapper.vue')
const OTCPage = () => import('@/pages/lib/OTCPage/OTCPage.vue')
const FAQPage = () => import('@/pages/lib/FAQPage/FAQPage.vue')
const PartnerLanding = () => import('@/pages/lib/PartnerLanding/PartnerLanding.vue')
const FeePage = () => import('@/pages/lib/FeePages/FeePage/FeePage.vue')
const TradingFee = () => import('@/pages/lib/FeePages/TradingFee/TradingFee.vue')
const WithdrawFee = () => import('@/pages/lib/FeePages/WithdrawFee/WithdrawFee.vue')
const NotFound = () => import('@/pages/lib/NotFound/NotFound.vue')
const LaunchpadPage = () => import('@/pages/lib/LaunchpadPage/LaunchpadPage.vue')
const GamesPage = () => import('@/pages/lib/GamesPage/GamesPage.vue')

Vue.use(VueRouter)
Vue.use(Meta, {
  ssrAppId: 1
})

const config = configProvider.getConfig()

export default ({ store }) => {
  const rawRoutes = [
    {
      path: '/',
      name: 'Landing',
      component: MainPage
    },
    {
      path: '/affiliate-program',
      name: 'Partner Landing',
      component: PartnerLanding
    },
    {
      path: '/fee',
      component: FeePage,
      children: [
        { path: '', redirect: 'withdraw' },
        { path: 'withdraw', name: 'WithdrawFee', component: WithdrawFee },
        { path: 'trading', name: 'TradingFee', component: TradingFee }
      ]
    },
    {
      path: '/about-us',
      name: 'AboutPage',
      component: AboutPage
    },
    {
      path: '/our-technology',
      name: 'TechnologyPage',
      component: TechnologyPage
    },
    {
      path: '/buy-:coin',
      name: 'CoinBuy',
      component: CoinPageWrapper
    },
    {
      path: '/sell-:coin',
      name: 'CoinSell',
      component: CoinPageWrapper
    },
    {
      path: '/trade-:coin',
      name: 'CoinTrade',
      component: CoinPageWrapper
    },
    {
      path: '/chart-:coin',
      name: 'CoinChart',
      component: CoinPageWrapper
    },
    {
      path: '/coin/:coin',
      name: 'Coin',
      component: CurrencyPageWrapper
    },
    {
      path: '/blog',
      name: 'BlogPage',
      component: BlogWrapper
    },
    {
      path: '/blog/category-:category',
      name: 'BlogCategoryPage',
      component: BlogWrapper
    },
    {
      path: '/blog/:url',
      name: 'ArticlePage',
      component: ArticleWrapper
    },
    {
      path: '/otc',
      name: 'OTCPage',
      component: OTCPage
    },
    {
      path: '/faq',
      name: 'FAQPage',
      component: FAQPage
    },
    {
      path: '/launchpad',
      name: 'LaunchpadPage',
      component: LaunchpadPage
    },
    {
      path: '/axie',
      name: 'GamesPage',
      component: GamesPage
    }
  ]

  let routes = []

  for (const languageConfig of config.languages) {
    const lang = languageConfig.symbol

    const langRoutes = []
    for (const route of rawRoutes) {
      let path = route.path

      if (lang !== 'en') {
        if (route.path === '/') {
          path = `/${lang}`
        } else {
          path = `/${lang}${route.path}`
        }
      }

      let name = route.name

      if (name) {
        name = lang === 'en' ? name : `${lang}-${name}`
      }

      let childrens = null
      if (route.children) {
        childrens = []

        for (const child of route.children) {
          if (child.redirect) {
            childrens.push({
              path: child.path,
              redirect: child.redirect
            })
          } else {
            let childName = child.name

            if (childName) {
              childName = lang === 'en' ? childName : `${lang}-${childName}`
            }

            childrens.push({
              path: child.path,
              name: childName,
              component: child.component,
              meta: {
                ...child.meta,
                language: lang
              }
            })
          }
        }
      }

      langRoutes.push({
        path: path,
        name,
        component: route.component,
        children: childrens,
        meta: {
          ...route.meta,
          language: lang
        }
      })
    }

    routes = routes.concat(langRoutes)
  }

  routes.push({ path: '*', component: NotFound })

  const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
  })

  router.beforeEach((to, from, next) => {
    let newQuery = {
      ...from.query
    }
    if (from.query) {
      newQuery = {
        ...newQuery,
        ...to.query
      }
    }
    if (!isEmpty(from.query) && isEmpty(to.query)) {
      if (to.path === from.path) {
        // console.log('Identical routes detected')
        return // This is a no-no via the documentation, but a bug in routing to identical routes strips query params, and this prevents that
      }
      next({ path: to.path, query: newQuery })
    }
    next()
  })

  router.afterEach((to, from, next) => {
    if (process.client) {
      roistat.sendEvent({
        eventName: Constants.actions.PAGE_VIEW,
        properties: {
          path: to.fullPath,
          previous: from.fullPath,
          timestamp: new Date().toISOString(),
          url: window.location.href
        }
      })
    }
  })

  return router
}
