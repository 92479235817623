import AbstractSocketDAO from './AbstractSocketDAO'
import PlasmaChannel from './PlasmaChannel'
import PlasmaTickerDataModel from 'src/models/core/lib/PlasmaTickerDataModel'
import PlasmaOrderbookGroupedModel from 'src/models/core/lib/PlasmaOrderbookGroupedModel'
import PlasmaOrderbookRawModel from 'src/models/core/lib/PlasmaOrderbookRawModel'

export default class PublicMarketDAO extends AbstractSocketDAO {
  constructor ({ market }) {
    super()
    // this.setMaxListeners(100)
    this.market = market
    this.marketSubscription = null
    this.baseTokenAddress = this.market.baseTokenAddress.toLowerCase()
    this.quoteTokenAddress = this.market.quoteTokenAddress.toLowerCase()
  }

  // eslint-disable-next-line no-unused-vars
  connect (client, api, options) {
    if (this.isConnected) {
      this.disconnect()
    }
    this.client = client
    this.api = api

    if (this.marketSubscription) {
      const { counter } = this.marketSubscription
      const { baseTokenAddress, quoteTokenAddress } = this
      this.marketSubscription.channels = this.openChannels()
      // eslint-disable-next-line
      console.info(`[PublicMarketDAO] Subscription to [baseTokenAddress,quoteTokenAddress] = [${baseTokenAddress},${quoteTokenAddress}] restored with ${counter} watcher(s) `)
    }
  }

  openChannels () {
    const { baseTokenAddress, quoteTokenAddress } = this
    return {
      orderbook: new PlasmaChannel(this.client, `^/orderbook/${this.market.symbol}`, null)
        .on('message', message => this.handleEvent(message))
        .on('subscribed', () => this.handleSubscribed({ baseTokenAddress, quoteTokenAddress, channel: 'orderbook' })),
      trade: new PlasmaChannel(this.client, `^/trade/${this.baseTokenAddress}/${this.quoteTokenAddress}$`, null)
        .on('message', message => this.handleEvent(message))
        .on('subscribed', () => this.handleSubscribed({ baseTokenAddress, quoteTokenAddress, channel: 'trade' })),
      ticker: new PlasmaChannel(this.client, `^/ticker/${this.market.symbol}/(?:.*)$`, null)
        .on('message', message => this.handleEvent(message))
        .on('subscribed', () => this.handleSubscribed({ baseTokenAddress, quoteTokenAddress, channel: 'ticker' })),
      ticker24: new PlasmaChannel(this.client, `^/ticker24/${this.market.symbol}$`, null)
        .on('message', message => this.handleEvent(message))
        .on('subscribed', () => this.handleSubscribed({ baseTokenAddress, quoteTokenAddress, channel: 'ticker24' }))
    }
  }

  subscribe () {
    let subscription = this.marketSubscription
    if (!subscription) {
      const channels = this.openChannels()
      subscription = this.marketSubscription = {
        channels,
        counter: 1
      }
    } else {
      subscription.counter++
    }
    return this
  }

  unsubscribe () {
    const subscription = this.marketSubscription
    if (!subscription) {
      // eslint-disable-next-line
      console.warn(`[PublicMarketDAO] Attempt to unsubscribe on invalid address: '${address}'`)
    } else {
      subscription.counter--
      if (subscription.counter === 0) {
        if (subscription.channels) {
          for (const channel of Object.values(subscription.channels)) {
            channel.removeAllListeners()
            channel.close()
          }
          subscription.channels = null
        }
        this.marketSubscription = null
      }
    }
    return this
  }

  disconnect () {
    if (this.isConnected) {
      if (this.marketSubscription && this.marketSubscription.channels) {
        for (const channel of Object.values(this.marketSubscription.channels)) {
          channel.removeAllListeners()
          channel.close()
        }
        this.marketSubscription.channels = null
      }

      this.client = null
      this.api = null
    }
  }

  handleEvent ({ event, payload }) {
    // console.log('PublicMarketDAO Event ->', { event, payload })
    setImmediate(() => {
      this.emit(event.type, { event, payload })
    })
  }

  handleState ({ state, payload }) {
    // console.log('PublicMarketDAO State ->', { state, payload })
    setImmediate(() => {
      this.emit(state.type, { state, payload })
    })
  }

  handleSubscribed ({ address, channel, ...rest }) {
    setImmediate(() => {
      this.emit('@_SUBSCRIBED', { address, channel, ...rest })
    })
  }

  async getSummary (period) {
    let tickers
    if (period === 'v24') {
      const { data } = await this.api.get('/public/tickers24', {
        params: {
          market: this.market.symbol
        }
      })
      tickers = data
    } else {
      const { data } = await this.api.get('/public/tickers', {
        params: {
          period,
          market: this.market.symbol
        }
      })
      tickers = data
    }
    return PlasmaTickerDataModel.fromJson(tickers[0])
  }

  async getOrderbookGrouped (step, limit = 100, owner) {
    const { data } = await this.api.get('/public/orderbook', {
      params: {
        market: this.market.symbol,
        step,
        limit,
        owner
      }
    })
    return PlasmaOrderbookGroupedModel.fromJson(data)
  }

  async getOrderbookRaw (limit = 100, owner) {
    const { data } = await this.api.get('/public/orderbook/raw', {
      params: {
        market: this.market.symbol,
        limit,
        owner
      }
    })
    return PlasmaOrderbookRawModel.fromJson(data)
  }
}
