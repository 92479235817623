/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'about-us/icon_07': {
    width: 41,
    height: 43,
    viewBox: '0 0 41 43',
    data: '<path pid="0" d="M.439 31.863l39.704-20.3" _stroke="#FDA431" stroke-width=".5" stroke-miterlimit="10"/>'
  }
})
