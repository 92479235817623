export default {
  paths: {
    src: '@'
  },
  plugins: [
    '@uvue/core/plugins/asyncData',
    '@uvue/core/plugins/errorHandler',
    [
      '@uvue/core/plugins/vuex',
      {
        fetch: true
      }
    ],
    '@/plugins/init.js',
    '@/plugins/services.js',
    '@/plugins/setup.js',
    '@/plugins/analytics.js'
  ]
}
