/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13 5.93L6.93 12 13 18.07l1.5-1.5L9.93 12l4.57-4.57-1.5-1.5z" _fill="#000"/>'
  }
})
