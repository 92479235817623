import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import AsyncLock from 'async-lock'
import { PASSPORT, withAuthorization } from 'src/remotes'
import { REGISTRY } from 'src/services'
import { roistat } from 'src/analytics'

const MIN_SECONDS_BEFORE_REFRESH = 20
const MIN_SECONDS_BEFORE_WARN = 40

export const PASSPORT_UPDATE = 'update'
export const PASSPORT_SIGNOUT = 'passport/signout'

const LOCK_REQUIRE_TOKEN = 'requireTokenLock'
const requireTokenLock = new AsyncLock({ domainReentrant: true })

export default () => ({
  namespaced: true,
  state () {
    return {
      token: null,
      refreshToken: null,
      expirationTime: null,
      user: null
    }
  },
  mutations: {
    [PASSPORT_UPDATE] (state, { token, refreshToken, expirationTime, user }) {
      if (user && user.id) {
        roistat.identify({ userId: user.id })
      }
      Object.assign(state, omitBy({
        token,
        refreshToken,
        expirationTime,
        user
      }, isNil))
    },
    [PASSPORT_SIGNOUT] (state) {
      state.token = null
      state.refreshToken = null
      state.expirationTime = null
      state.user = null
    }
  },
  getters: {
    expirationTime: state => state.expirationTime,
    user: state => state.user
  },
  actions: {
    async requireToken ({ dispatch }) {
      return requireTokenLock.acquire(
        LOCK_REQUIRE_TOKEN,
        async () => {
          const token = await dispatch('accessToken')
          if (token == null) {
            // eslint-disable-next-line
            console.info('[passport] No token found.')
            await dispatch('signout')
            throw new Error('No token found')
          }
          return token
        }
      )
    },
    async accessToken ({ state, dispatch }) {
      try {
        if (state.token == null) {
          throw new Error('No token provided')
        }
        if (state.refreshToken == null) {
          throw new Error('No refreshToken provided')
        }
        if (state.expirationTime == null) {
          throw new Error('No expirationTime provided')
        }

        const nowSeconds = Math.floor(Date.now() / 1000)
        const diff = state.expirationTime - nowSeconds

        if (diff > MIN_SECONDS_BEFORE_REFRESH) {
          if (diff <= MIN_SECONDS_BEFORE_WARN) {
            // eslint-disable-next-line
            console.info(`[passport] Token will expire in ${diff} second(s)`)
          }
          return state.token
        } else {
          // eslint-disable-next-line
          console.info('[passport] Token has expired. Attempt to refresh token.')
        }

        const { data } = await PASSPORT.post('security/refresh', null, withAuthorization(state.refreshToken))

        const {
          user,
          token,
          refreshToken
        } = data

        await dispatch('session', {
          user: Object.freeze(user),
          token,
          refreshToken
        })

        // eslint-disable-next-line
        console.info(`[passport] Token has been refreshed.`)

        return token
      } catch (e) {
        // eslint-disable-next-line
        console.info('[passport] No token found.', e)

        return null
      }
    },
    async session ({ commit, dispatch }, { user, token, refreshToken }) {
      const { expirationTime } = await dispatch('decodeToken', { token })
      commit(PASSPORT_UPDATE, {
        user,
        token,
        refreshToken,
        expirationTime
      })
    },
    async decodeToken ({ rootGetters }, { token }) {
      const pubKey = rootGetters['config/jwtPubKey']
      const authConfig = rootGetters['config/config'].auth
      const passportClient = REGISTRY.getService('passportClient')
      if (token == null || pubKey == null || passportClient == null) {
        return null
      }
      return passportClient.decodeAndVerifyToken({
        token,
        pubKey,
        clientId: authConfig.clientId
      })
    },
    async recover ({ state, commit, dispatch }) {
      const token = await dispatch('accessToken')
      if (token != null) {
        try {
          const { expirationTime } = await dispatch('decodeToken', { token })
          const user = await dispatch('fetch', { token })
          commit(PASSPORT_UPDATE, {
            user: Object.freeze(user),
            expirationTime
          })
          return
        } catch (e) {
          // eslint-disable-next-line
          console.info('[passport] Cannot restore session', e)
        }
      }

      await dispatch('signout')
    },
    async fetch (_, { token }) {
      const { data } = await PASSPORT.get('security/me', withAuthorization(token))
      return Object.freeze(data)
    },
    async signout ({ commit }) {
      commit(PASSPORT_SIGNOUT)
    }
  }
})
