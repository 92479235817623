/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'account': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 11.667c-2.5 0-7.5 1.256-7.5 3.75V17.5h15v-2.083c0-2.494-5-3.75-7.5-3.75zm4.167-5.834a4.167 4.167 0 10-8.334 0 4.167 4.167 0 008.334 0z" _fill="#fff" fill-opacity=".7"/>'
  }
})
