import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { mapActions, mapState, mapGetters } from 'vuex'
import { describeAxiosException } from 'src/utils'

import ModalStack from 'src/components/lib/ModalStack/ModalStack.vue'
import SupportButton from 'src/components/lib/SupportButton/SupportButton.vue'

export default {
  components: {
    ModalStack,
    SupportButton
  },
  computed: {
    ...mapState({
      socials: state => state.public.socials,
      webPages: state => state.public.webPages,
      currenciesIsLoading: state => state.currencies.isLoading,
      currenciesIsLoaded: state => state.currencies.isLoaded,
      passport: state => state.passport?.user
    }),
    ...mapGetters({
      config: 'config/config'
    })
  },
  watch: {
    $route: {
      handler () {
        this.saveReferral()
      },
      immediate: true
    }
  },
  metaInfo () {
    const meta = []
    const link = []
    const metainfoScripts = []
    let title = 'TimeX'

    const currentPath = this.$route.path
    if (this.webPages) {
      for (const webPage of this.webPages) {
        if (webPage.path.toLowerCase() === currentPath.toLowerCase()) {
          webPage.metatags.forEach(metatag => {
            meta.push({
              name: metatag.name,
              content: metatag.content
            })
          })
          title = webPage.title

          if (webPage.canonical) {
            link.push({
              rel: 'canonical',
              href: webPage.canonical
            })
          }
          break
        }
      }
    }
    if (this.config.meta) {
      for (const metaConf of this.config.meta) {
        meta.push({
          name: metaConf.name,
          content: metaConf.content
        })
      }
    }

    const langLinks = this.config.languages.map(e => ({
      rel: 'alternate',
      href: this.getLocalizedUrlForCurrntPage(e.symbol),
      hreflang: e.symbol === 'en' ? 'x-default' : e.symbol
    }))

    langLinks.forEach(e => link.push(e))

    return {
      title,
      meta,
      link,
      script: metainfoScripts
    }
  },
  async serverPrefetch () {
    try {
      await Promise.all([
        this.loadSections(),
        this.loadTemplates(),
        this.loadSocials(),
        this.loadExternalLinks(),
        this.loadWebPages(),
        this.loadOurProducts(),
        this.loadFooterCoins(),
        this.loadPapers(),
        this.loadCompanyContancts(),
        this.loadCurrencies(),

        // TODO: this for concrete pages.
        this.loadPartners(),
        this.loadContacts(),
        this.loadOtcContancts(),
        this.loadMembers()
      ])
    } catch (e) {
      console.log('app.serverPrefetch.error;', describeAxiosException(e) || e.message)
      throw new Error('Cannot load data')
    }
  },
  methods: {
    ...mapActions({
      loadSections: 'public/loadSections',
      loadTemplates: 'public/loadTemplates',
      loadPartners: 'public/loadPartners',
      loadMembers: 'public/loadMembers',
      loadSocials: 'public/loadSocials',
      loadPapers: 'public/loadPapers',
      loadContacts: 'public/loadContacts',
      loadOtcContancts: 'public/loadOtcContancts',
      loadCompanyContancts: 'public/loadCompanyContancts',
      loadExternalLinks: 'public/loadExternalLinks',
      loadWebPages: 'public/loadWebPages',
      loadOurProducts: 'public/loadOurProducts',
      resizeWindow: 'ui/resizeWindow',
      publicLoadMarkets: 'markets/loadMarkets',
      initializeRatesDAO: 'daos/initializeRatesDAO',
      setRefcode: 'routes/setRefcode',
      routesClear: 'routes/clear',
      loadCurrencies: 'currencies/loadCurrencies',
      selectLanguage: 'language/selectLanguage',
      loadFooterCoins: 'public/loadFooterCoins',
      updateSessionTimer: 'ui/updateSessionTimer',
      logout: 'passport/signout'
    }),
    clickMethod () {
      this.updateSessionTimer()
    },
    saveReferral () {
      const { refcode } = this.$route.query
      if (refcode) {
        this.setRefcode({ refcode })
      }
    }
  },
  async mounted () {
    this.updateSessionTimer()
    document.addEventListener('click', this.clickMethod)
    this.resizeListener = debounce(() => {
      this.resizeWindow({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }, 500)
    this.resizeWindow({
      width: window.innerWidth,
      height: window.innerHeight
    })
    window.addEventListener('resize', this.resizeListener)
    try {
      await Promise.all([
        this.initializeRatesDAO(),
        this.publicLoadMarkets()
      ])
    } catch (e) {
      console.log(e)
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.clickMethod)
    this.routesClear()
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener)
      this.resizeListener = null
    }
  },
  created () {
    const language = get(this.$route, 'meta.language', 'en')
    this.selectLanguage(language)
  }
}
