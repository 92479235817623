import addDays from 'date-fns/addDays'
import { PLASMA } from 'src/remotes'
import { retry } from 'src/utils'

export const CANDLES_SET = 'publicCandles/set'
export const CANDLE_SET = 'publicCandle/set'

export default () => ({
  namespaced: true,
  state: {
    candles: {},
    candle: null
  },
  mutations: {
    [CANDLES_SET]: (state, { market, candle }) => {
      state.candles = {
        ...state.candles,
        [market.market.symbol]: candle
      }
    },
    [CANDLE_SET]: (state, { candle }) => {
      state.candle = candle
    }
  },
  actions: {
    async loadCandles ({ commit }, { markets }) {
      await Promise.all(
        markets.map(
          m => new Promise((resolve, reject) => {
            retry(async () => {
              const { data } = await PLASMA.get('/public/candles', {
                params: {
                  market: m.market.symbol,
                  period: 'H1',
                  from: addDays(new Date(), -2).toISOString(),
                  till: new Date().toISOString()
                }
              })
              commit(CANDLES_SET, { market: m, candle: data })
            })
          })
        )
      )
    },
    async loadCandle ({ commit }, { market }) {
      const { data } = await PLASMA.get('/public/candles', {
        params: {
          market: market.market.symbol,
          period: 'H1',
          from: addDays(new Date(), -2).toISOString(),
          till: new Date().toISOString()
        }
      })
      commit(CANDLE_SET, { candle: data })
    }
  }
})
