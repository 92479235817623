/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'about-us/icon_03': {
    width: 43,
    height: 44,
    viewBox: '0 0 43 44',
    data: '<path pid="0" d="M13.255 24.799l-5.49-11c-.74-1.484-2.475-2.082-3.93-1.357-1.454.726-2.019 2.473-1.279 3.956l5.49 11" _stroke="#FDA431" stroke-width=".75" stroke-miterlimit="10"/><path pid="1" d="M18.463 22.201l-5.49-11c-.74-1.483-2.475-2.082-3.93-1.356-1.454.726-2.019 2.472-1.279 3.955l5.49 11" _stroke="#FDA431" stroke-width=".75" stroke-miterlimit="10"/><path pid="2" d="M23.672 19.604l-5.49-11.001c-.74-1.483-2.474-2.082-3.929-1.356-1.454.726-2.02 2.472-1.28 3.955l5.49 11.001M28.881 17.002l-5.49-11c-.74-1.484-2.474-2.082-3.929-1.356-1.454.725-2.02 2.472-1.28 3.955l5.49 11M34.127 14.385l-5.49-11.001c-.74-1.483-2.475-2.081-3.93-1.356-1.454.726-2.019 2.473-1.279 3.956l5.49 11" _stroke="#FDA431" stroke-width=".75" stroke-miterlimit="10"/><path pid="3" d="M7.1 25.504l2.099 4.207c3.665 7.346 12.443 10.374 19.648 6.778 7.205-3.595 10.063-12.43 6.398-19.775l-2.1-4.208" _stroke="#FDA431" stroke-width=".75" stroke-miterlimit="10"/>'
  }
})
