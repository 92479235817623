/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'setup': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M16.25 3.333V1.667h-1.667v1.666h-1.25v10h1.25v3.334h1.667v-3.334h1.25v-10h-1.25zm-1.667 8.75v-7.5h1.667v7.5h-1.667zM10.834 1.667H9.167v1.666h-1.25v13.334h1.25v1.666h1.667v-1.666h1.25V3.333h-1.25V1.667zM5.417 3.333H3.75v3.334H2.5v6.666h1.25v3.334h1.667v-3.334h1.25V6.667h-1.25V3.333zm0 8.75H3.75V7.917h1.667v4.166z" _fill="#fff" fill-opacity=".7"/>'
  }
})
