import EventEmitter from 'events'

export default class AbstractSocketDAO extends EventEmitter {
  get isConnected () {
    return this.client != null
  }

  connect (client, api) {
    if (this.isConnected) {
      this.disconnect()
    }
    this.client = client
    this.api = api
  }

  disconnect () {
    if (this.isConnected) {
      this.client = null
    }
  }
}
