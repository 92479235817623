/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'security': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 .833L2.5 4.167v5a10.35 10.35 0 007.5 10 10.349 10.349 0 007.5-10v-5L10 .833zm-.833 12.845l-3.488-3.487 1.179-1.178 2.309 2.309 4.826-4.827 1.179 1.178-6.005 6.005z" _fill="#fff" fill-opacity=".7"/>'
  }
})
