class ConfigProvider {
  constructor () {
    const self = {
      url: process.env.VUE_APP_SELF_URL
    }
    const pages = {
      url: process.env.VUE_APP_PAGES_URL
    }
    const publicService = {
      imageUrl: process.env.VUE_APP_PUBLIC_SERVICE_IAMGE_URL,
      endpoint: {
        baseURL: process.env.VUE_APP_PUBLIC_SERVICE_BASE_URL,
        timeout: process.env.VUE_APP_PUBLIC_SERVICE_TIMEOUT
      }
    }
    const plasmaService = {
      endpoint: {
        baseURL: process.env.VUE_APP_PLASMA_SERVICE_BASE_URL,
        timeout: process.env.VUE_APP_PLASMA_SERVICE_TIMEOUT
      },
      socket: process.env.VUE_APP_PLASMA_SERVICE_SOCKET_URL
    }
    const backendService = {
      endpoint: {
        baseURL: process.env.VUE_APP_BACKEND_SERVICE_BASE_URL,
        timeout: process.env.VUE_APP_BACKEND_SERVICE_TIMEOUT
      }
    }
    const profileService = {
      endpoint: {
        baseURL: process.env.VUE_APP_PROFILE_SERVICE_BASE_URL,
        timeout: process.env.VUE_APP_PROFILE_SERVICE_TIMEOUT
      }
    }
    const passportService = {
      endpoint: {
        baseURL: process.env.VUE_APP_PASSPORT_SERVICE_BASE_URL,
        timeout: process.env.VUE_APP_PASSPORT_SERVICE_TIMEOUT
      }
    }
    const ratesService = {
      endpoint: {
        baseURL: process.env.VUE_APP_RATES_SERVICE_BASE_URL,
        timeout: process.env.VUE_APP_RATES_SERVICE_TIMEOUT
      },
      socket: process.env.VUE_APP_RATES_SERVICE_SOCKET_URL
    }
    const jwt = {
      pubKey: process.env.VUE_APP_JWT_PUBKEY
    }

    const auth = {
      passportUrl: process.env.VUE_APP_AUTH_PASSPORT_URL,
      redirectUrl: process.env.VUE_APP_AUTH_PASSPORT_REDIRECT_URL,
      clientId: process.env.VUE_APP_AUTH_PASSPORT_CLIENT_ID
    }

    // other integrations
    let zendesk
    if (process.env.VUE_APP_ZENDESK_ID && process.env.VUE_APP_ZENDESK_SRC) {
      zendesk = {
        id: process.env.VUE_APP_ZENDESK_ID,
        src: process.env.VUE_APP_ZENDESK_SRC
      }
    }

    let roistat
    if (process.env.VUE_APP_ROISTAT_KEY) {
      roistat = {
        key: process.env.VUE_APP_ROISTAT_KEY
      }
    }

    let pixel
    if (process.env.VUE_APP_PIXEL_ID) {
      pixel = {
        id: process.env.VUE_APP_PIXEL_ID
      }
    }

    let google
    if (process.env.VUE_APP_GOOGLE_GTAG_ID) {
      google = {
        gtag: {
          id: process.env.VUE_APP_GOOGLE_GTAG_ID
        }
      }
    }

    let meta
    if (process.env.VUE_APP_META) {
      meta = JSON.parse(process.env.VUE_APP_META)
    }

    this.appConfig = {
      languages: [
        {
          symbol: 'en',
          title: 'English'
        },
        {
          symbol: 'ru',
          title: 'Русский'
        }
      ],
      autoCloseModalTimeSeconds: process.env.VUE_APP_AUTO_CLOSE_MODAL_TIME_SECONDS,
      logoutTimeSeconds: process.env.VUE_APP_LOGOUT_TIME_SECONDS,
      self,
      pages,
      publicService,
      plasmaService,
      backendService,
      profileService,
      passportService,
      ratesService,
      jwt,
      auth,

      zendesk,
      roistat,
      pixel,
      google,
      meta
    }
  }

  getConfig () {
    return this.appConfig
  }
}

const instance = new ConfigProvider()
export default instance
