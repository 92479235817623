export default {
  props: {
    text: String,
    withLink: Boolean,
    linkText: String,
    linkPath: String,
    htmlText: String,
    name: {
      type: String,
      default: 'touch-id'
    }
  },
  computed: {}
}
