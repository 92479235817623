export const SET_COOKIE_STATE = 'cookieStateToggle'

export default () => ({
  namespaced: true,
  state: () => ({
    isCookiesBarVisible: true
  }),
  getters: {
    isCookiesBarVisible: state => state.isCookiesBarVisible
  },
  mutations: {
    [SET_COOKIE_STATE] (state) {
      state.isCookiesBarVisible = !state.isCookiesBarVisible
    }
  },
  actions: {
    async cookieStateToggle ({ commit }) {
      commit(SET_COOKIE_STATE)
    }
  }
})
