import Joi from 'joi'
import AbstractModel from './AbstractModel'

export default class PlasmaOrderbookRawModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      timestamp: Joi.date().required(),
      ask: Joi.array().items(
        Joi.object().keys({
          market: Joi.string().required(),
          direction: Joi.string().allow('BUY', 'SELL').required(),
          createdAt: Joi.date().required(),
          expiredAt: Joi.date().required(),
          hash: Joi.string().required(),
          price: Joi.string().required(),
          quantity: Joi.string().required()
        })
      ).required(),
      bid: Joi.array().items(
        Joi.object().keys({
          market: Joi.string().required(),
          direction: Joi.string().allow('BUY', 'SELL').required(),
          createdAt: Joi.date().required(),
          expiredAt: Joi.date().required(),
          hash: Joi.string().required(),
          price: Joi.string().required(),
          quantity: Joi.string().required()
        })
      ).required()
    }
  }

  static fromJson (data) {
    return new PlasmaOrderbookRawModel({
      ...data
    })
  }
}
