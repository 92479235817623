import axios from 'axios'
import { SimpleCache } from 'src/cache'
import configProvider from 'src/configProvider'

class ExchangeBackendService {
  constructor () {
    this.isClientProcess = process.client
    this.client = null

    if (!this.isClientProcess) {
      this.cache = new SimpleCache()
    }
  }

  init () {
    if (this.isClientProcess) {
      this.client = axios.create(configProvider.getConfig().backendService.endpoint)
    } else {
      this.client = axios.create(global.__SERVER_CONFIG__.backendService.endpoint)
    }
  }

  async getCurrencies () {
    if (this.isClientProcess) {
      return this.client.get('/currencies')
    } else {
      return this.cache.getOrStore('currencies', async () => {
        const { data } = await this.client.get('/currencies')
        return { data }
      })
    }
  }
}

const instance = new ExchangeBackendService()
export default instance
