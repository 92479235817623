/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'singin': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M14.167 10l-3.334-3.333v2.5H2.5v1.666h8.333v2.5L14.167 10zm-7.5-4.167h1.666V4.167h7.5v11.666h-7.5v-1.666H6.667v1.666A1.68 1.68 0 008.333 17.5h7.5a1.68 1.68 0 001.667-1.667V4.167A1.68 1.68 0 0015.833 2.5h-7.5a1.68 1.68 0 00-1.666 1.667v1.666z" />'
  }
})
