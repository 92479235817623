/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hand': {
    width: 17,
    height: 26,
    viewBox: '0 0 17 26',
    data: '<path pid="0" d="M5.25 10.5V1.75a1.25 1.25 0 0 0-2.5 0v12.971l4.454-1.8A2.467 2.467 0 0 1 5.25 10.5zM11.5 13h1.25V9.25A1.25 1.25 0 0 0 11.5 8h-1.25v3.75A1.25 1.25 0 0 0 11.5 13z" _fill="#fff"/><path pid="1" d="M7.75 11.75H9V8a1.25 1.25 0 0 0-1.25-1.25H6.5v3.75a1.25 1.25 0 0 0 1.25 1.25zM13.101 14.25H11.5a2.463 2.463 0 0 1-1.796-.781l.195.547a3.314 3.314 0 0 1-1.758 4.258l-1.015.43a4.697 4.697 0 0 1 3.164 2.382l-1.132.548a3.75 3.75 0 0 0-3.087-1.837H5.68l-.702-1.523 2.695-1.133a2.068 2.068 0 0 0 1.093-2.656l-.273-.702-6.954 2.772a2.799 2.799 0 0 0-.859 3.906l2.423 3.673A3.046 3.046 0 0 0 5.64 25.5H11.5a5 5 0 0 0 5-5v-5h-1.25a2.488 2.488 0 0 1-2.149-1.25zM15.25 9.25H14V13a1.25 1.25 0 0 0 1.25 1.25h1.25V10.5a1.25 1.25 0 0 0-1.25-1.25z" _fill="#fff"/>'
  }
})
