/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'twitter': {
    width: 17,
    height: 15,
    viewBox: '0 0 17 15',
    data: '<path pid="0" opacity=".8" d="M17 1.759a6.421 6.421 0 01-1.983.62c.708-.465 1.275-1.241 1.558-2.12-.661.413-1.417.775-2.22.93C13.696.467 12.798 0 11.759 0 9.822 0 8.264 1.707 8.264 3.776c0 .31.047.569.094.88C5.478 4.5 2.928 3 1.181.671c-.284.57-.473 1.19-.473 1.914 0 1.293.614 2.483 1.559 3.155-.567 0-1.134-.207-1.559-.465v.052c0 1.862 1.18 3.362 2.786 3.724a2.73 2.73 0 01-.897.155c-.236 0-.425-.052-.66-.052.424 1.5 1.746 2.586 3.257 2.638-1.18 1.035-2.691 1.604-4.344 1.604-.283 0-.567 0-.85-.052C1.558 14.379 3.4 15 5.336 15c6.422 0 9.917-5.793 9.917-10.759v-.465A7.621 7.621 0 0017 1.759z" _fill="#111"/>'
  }
})
