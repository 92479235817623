export const SELECT_LANGUAGE = 'language/select'

export default () => ({
  namespaced: true,
  state: {
    language: 'en'
  },
  mutations: {
    [SELECT_LANGUAGE] (state, language) {
      state.language = language
    }
  },
  getters: {
    language: state => state.language
  },
  actions: {
    selectLanguage ({ state, commit }, language) {
      commit(SELECT_LANGUAGE, language)
    }
  }
})
