import { LoadableListModel, PublicPlasmaTickerDataModel } from 'src/models'
import { PLASMA } from 'src/remotes'
import { retry } from 'src/utils'

export const TICKERS_LOADING = 'publicTickers/loading'
export const TICKERS_LOADED = 'publicTickers/loaded'

export default () => ({
  namespaced: true,
  state: {
    list: new LoadableListModel(PublicPlasmaTickerDataModel)
  },
  mutations: {
    [TICKERS_LOADING]: (state) => {
      state.list = state.list.loading()
    },
    [TICKERS_LOADED]: (state, { tickers }) => {
      state.list = state.list.loaded(...tickers)
    }
  },
  getters: {
    getTickerBySymbol: state => symbol => state.list.isLoaded && state.list.value.find(
      market => market.market === symbol
    )
  },
  actions: {
    async fetchTickers ({ state, commit }) {
      const { data } = await PLASMA.get('/public/tickers24')
      const tickers = data.map(PublicPlasmaTickerDataModel.fromJson)
      return tickers
    },
    async loadTickers ({ state, commit, dispatch, rootState, rootGetters }) {
      commit(TICKERS_LOADING)
      await retry(async function () {
        const tickers = await dispatch('fetchTickers')
        commit(TICKERS_LOADED, { tickers })
      })
    }
  }
})
