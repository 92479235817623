import configProvider from 'src/configProvider'

const config = configProvider.getConfig()
const jwtConfig = config.jwt

export default () => ({
  namespaced: true,
  getters: {
    config: () => config,
    jwtPubKey: () => Buffer.from(jwtConfig.pubKey)
  }
})
