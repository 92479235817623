import addDays from 'date-fns/addDays'
import {
  LoadableMapModel,
  LoadableListModel,
  RateModel,
  MarketModel,
  PublicPlasmaTickerDataModel,
  PlasmaTradeModel,
  PlasmaOrderbookGroupedModel,
  SeoCurrencyModel
} from 'src/models'
import { retry } from 'src/utils'
import { RATES, PLASMA } from 'src/remotes'
import publicBackendService from 'src/publicBackendService'

const CHANGE_CURRENCY = 'coins/change/currency'

const COINS_RATE_LOADED = 'coins/rate/loaded'
const COINS_RATE_LOADING = 'coins/rate/loading'

const COINS_MARKETS_LOADED = 'coins/markets/loaded'
const COINS_MARKETS_LOADING = 'coins/markets/loading'

const COINS_TICKERS_LOADING = 'coins/tickers/loading'
const COINS_TICKERS_LOADED = 'coins/tickers/loaded'

const COINS_TRADES_LOADED = 'coins/trades/loaded'

const COINS_ORDERBOOK_LOADED = 'coins/orderbook/loaded'

const COINS_CANDLES_SET = 'coins/candles/set'

const COINS_BEFORE_READY = 'coins/before/ready'
const COINS_SET_DEFAULT_MARKET = 'coins/set/default/market'
const COINS_SET_CURRENT_MARKET = 'coins/set/current/market'

const COINS_CURRENCIES_LOADING = 'coins/currencies/loading'
const COINS_CURRENCIES_LOADED = 'coins/currencies/loaded'

export default () => ({
  namespaced: true,
  state: {
    currencies: new LoadableListModel(SeoCurrencyModel),
    candles: new LoadableMapModel(Array),
    tableRates: new LoadableMapModel(RateModel),
    markets: new LoadableListModel(MarketModel),
    tickers: new LoadableListModel(PublicPlasmaTickerDataModel),
    trades: new LoadableMapModel(Array),
    orderbooks: new LoadableMapModel(PlasmaOrderbookGroupedModel),
    fiatCurrencies: ['AUD', 'USD'],
    fiatCurrenciesName: {
      AUD: 'Australian Dollar',
      USD: 'American Dollar'
    },
    fiatCurrency: 'AUD',
    currentMarket: null
  },
  getters: {
    fiatCurrency: state => state.fiatCurrency,
    getRate: state => (from, to) => state.tableRates.get(`${from}:${to}`) || new RateModel({ from, to }),
    getTrades: state => symbol => state.trades.get(symbol),
    getOrderbook: state => symbol => state.orderbooks.get(symbol),
    getCurrencyBySymbol: state => symbol => state.currencies.isLoaded && state.currencies.value.find(
      currency => currency.symbol.toLowerCase() === symbol.toLowerCase()
    ),
    getMarketBySymbol: state => symbol => state.markets.isLoaded && state.markets.value.find(
      market => market.symbol === symbol
    )
  },
  mutations: {
    [CHANGE_CURRENCY] (state, fiatCurrency) {
      state.fiatCurrency = fiatCurrency
    },
    [COINS_CANDLES_SET]: (state, { market, candle }) => {
      state.candles = state.candles.put(market.symbol, candle)
    },
    [COINS_RATE_LOADING]: (state, { from, to, price }) => {
      const model = state.tableRates.get(`${from}:${to}`) || new RateModel({ from, to, price })
      state.tableRates = state.tableRates.put(`${from}:${to}`, model.loading())
    },
    [COINS_RATE_LOADED]: (state, { from, to, price }) => {
      const model = state.tableRates.get(`${from}:${to}`) || new RateModel({ from, to, price })
      state.tableRates = state.tableRates.put(`${from}:${to}`, model.loaded({ price }))
    },
    [COINS_MARKETS_LOADING]: (state) => {
      state.markets = state.markets.loading()
    },
    [COINS_MARKETS_LOADED]: (state, { markets }) => {
      state.markets = state.markets.loaded(...markets)
    },
    [COINS_TICKERS_LOADING]: (state) => {
      state.tickers = state.tickers.loading()
    },
    [COINS_TICKERS_LOADED]: (state, { tickers }) => {
      state.tickers = state.tickers.loaded(...tickers)
    },
    [COINS_BEFORE_READY]: (state) => {
      state.markets = new LoadableListModel(MarketModel).loaded(...state.markets.value.map(MarketModel.fromJson))
      state.tickers = new LoadableListModel(PublicPlasmaTickerDataModel).loaded(...state.tickers.value.map(PublicPlasmaTickerDataModel.fromJson))
      state.tableRates = Object.values(state.tableRates.data).reduce((accumulator, rate) => {
        return accumulator.put(`${rate.from}:${rate.to}`, new RateModel({
          from: rate.from,
          to: rate.to,
          price: rate.price,
          isLoaded: true,
          isLoading: false
        }))
      }, new LoadableMapModel(RateModel))
      state.candles = Object.keys(state.candles.data).reduce((accumulator, symbol) => {
        return accumulator.put(symbol, state.candles.data[symbol])
      }, new LoadableMapModel(Array))
      state.trades = Object.keys(state.trades.data).reduce((accumulator, symbol) => {
        return accumulator.put(symbol, state.trades.data[symbol])
      }, new LoadableMapModel(Array))
      state.orderbooks = Object.keys(state.orderbooks.data).reduce((accumulator, symbol) => {
        return accumulator.put(symbol, PlasmaOrderbookGroupedModel.fromJson(state.orderbooks.data[symbol]))
      }, new LoadableMapModel(PlasmaOrderbookGroupedModel))
    },
    [COINS_TRADES_LOADED]: (state, { market, trades }) => {
      state.trades = state.trades.put(market.symbol, trades)
    },
    [COINS_ORDERBOOK_LOADED]: (state, { market, orderbook }) => {
      state.orderbooks = state.orderbooks.put(market.symbol, orderbook)
    },
    [COINS_SET_DEFAULT_MARKET]: (state, coin) => {
      state.currentMarket = state.markets.value
        .find(market => {
          const isCurrentMarket = market.baseCurrency.toLowerCase() === coin.toLowerCase() ||
            market.quoteCurrency.toLowerCase() === coin.toLowerCase()
          return isCurrentMarket && !market.locked
        })
    },
    [COINS_SET_CURRENT_MARKET]: (state, { market }) => {
      state.currentMarket = market
    },
    [COINS_CURRENCIES_LOADING]: (state) => {
      state.currencies = new LoadableListModel(SeoCurrencyModel, {
        isLoaded: false,
        isLoading: true,
        value: []
      })
    },
    [COINS_CURRENCIES_LOADED]: (state, { items }) => {
      state.currencies = new LoadableListModel(SeoCurrencyModel, {
        isLoaded: true,
        isLoading: false,
        value: items.map(SeoCurrencyModel.fromJson)
      })
    }
  },
  actions: {
    beforeReady ({ commit }) {
      commit(COINS_BEFORE_READY)
    },
    changeCurrency ({ commit }, { fiatCurrency }) {
      commit(CHANGE_CURRENCY, fiatCurrency)
    },
    async loadMarkets ({ commit }) {
      commit(COINS_MARKETS_LOADING)
      const { data } = await PLASMA.get('public/markets')
      const markets = data.map(MarketModel.fromJson)
      markets.sort((a, b) => a.sortOrder - b.sortOrder)
      commit(COINS_MARKETS_LOADED, { markets })
      return markets
    },
    async loadCurrencies ({ commit }) {
      commit(COINS_CURRENCIES_LOADING)
      const { data } = await publicBackendService.getSeoCurrencies()
      commit(COINS_CURRENCIES_LOADED, {
        items: data.currencies.map(item => SeoCurrencyModel.fromJson(item))
      })
      return data
    },
    async loadTickers ({ commit }) {
      commit(COINS_TICKERS_LOADING)
      const { data } = await PLASMA.get('/public/tickers24')
      const tickers = data.map(PublicPlasmaTickerDataModel.fromJson)
      commit(COINS_TICKERS_LOADED, { tickers })
    },
    async loadRate ({ commit }, { from, to }) {
      if (from === to) {
        return
      }

      commit(COINS_RATE_LOADING, { from, to })
      await retry(async () => {
        const { data } = await RATES.get('/rates', { params: { from, to } })
        commit(COINS_RATE_LOADED, data)
      })
    },
    async loadRates ({ state, dispatch }, { markets }) {
      for (const market of markets) {
        try {
          await dispatch('loadRate', { from: market.quoteCurrency, to: state.fiatCurrency })
        } catch (e) {
          console.log(e)
        }
      }
    },
    async loadCandles ({ commit }, { markets }) {
      return await Promise.all(markets.map(async market => {
        const { data } = await PLASMA.get('/public/candles', {
          params: {
            market: market.symbol,
            period: 'H1',
            from: addDays(new Date(), -2).toISOString(),
            till: new Date().toISOString()
          }
        })
        commit(COINS_CANDLES_SET, { market, candle: data })
        return data
      }))
    },
    async loadTrade ({ commit }, { market }) {
      const { data } = await PLASMA.get('/public/trades', {
        params: {
          market: market.symbol,
          size: 4
        }
      })
      commit(COINS_TRADES_LOADED, { market, trades: data.map(PlasmaTradeModel.fromJs) })
    },
    async loadOrderbook ({ commit }, { market }) {
      const { data } = await PLASMA.get('/public/orderbook/v2', {
        params: {
          market: market.symbol,
          limit: 4
        }
      })
      commit(COINS_ORDERBOOK_LOADED, { market, orderbook: PlasmaOrderbookGroupedModel.fromJson(data) })
    },
    async setDefaultMarket ({ commit }, { coin }) {
      commit(COINS_SET_DEFAULT_MARKET, coin)
    },
    changeMarket ({ commit }, { market }) {
      commit(COINS_SET_CURRENT_MARKET, { market })
    },
    async loadInitialData ({ dispatch, state }, { rateServiceSymbol }) {
      await dispatch('loadMarkets')
      await dispatch('loadInitialRate', { rateServiceSymbol })
    },
    async loadInitialRate ({ dispatch, state }, { rateServiceSymbol }) {
      if (rateServiceSymbol) {
        await dispatch('loadRate', { from: rateServiceSymbol.toUpperCase(), to: state.fiatCurrency })
      }
    },
    async loadMarketsData ({ dispatch }, { markets }) {
      await dispatch('loadTickers')
      await dispatch('loadCandles', { markets })
      await dispatch('loadRates', { markets })
    },
    async loadTradeData ({ dispatch }, { market }) {
      await dispatch('loadTrade', { market })
      await dispatch('loadOrderbook', { market })
    }
  }
})
