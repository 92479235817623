export default class PassportClient {
  async decodeAndVerifyToken ({ token, pubKey, clientId }) {
    const [, payload] = token.split('.')
    const decoded = JSON.parse(global.atob(payload))

    const isPassportAud = decoded.aud === 'passport'
    const isClientAud = clientId != null && decoded.aud === `urn:${clientId}`

    if (!isPassportAud && !isClientAud) {
      throw new Error('Invalid issuer')
    }

    const [userId, credentialId] = decoded.sub.split(':')

    return {
      userId,
      credentialId,
      type: decoded.type,
      issuedAt: decoded.iat,
      expirationTime: decoded.exp
    }
  }
}
