import configProvider from 'src/configProvider'
import LogoutModal from 'src/modals/lib/LogoutModal/LogoutModal.vue'
export const WINDOW_SIZE = 'window/mode'
export const SET_IS_PRIVATE_TOOLBAR_OVERFLOWED = 'ui/setIsPrivateToolbarOverflowed'
export const SET_IS_LANGUAGE_MENU_VISIBLE = 'ui/setIsLanguageMenuVisible'
export const SET_IS_TRADE_MENU_VISIBLE = 'ui/setIsTradeMenuVisible'

export const CLEAR_SESSION_TIMER = 'ui/sessionTimer/clear'

const config = configProvider.getConfig()

export default () => ({
  namespaced: true,
  state: {
    fiatCurrency: 'USD',
    windowSize: null,
    isPrivateToolbarOverflowed: false,
    isTradeMenuVisible: false,
    isLanguageMenuVisible: false,

    timerId: null
  },
  mutations: {
    [CLEAR_SESSION_TIMER] (state) {
      clearTimeout(state.timerId)
    },
    [WINDOW_SIZE] (state, windowSize) {
      state.windowSize = windowSize
    },
    [SET_IS_PRIVATE_TOOLBAR_OVERFLOWED] (state, { isPrivateToolbarOverflowed }) {
      state.isPrivateToolbarOverflowed = isPrivateToolbarOverflowed
    },
    [SET_IS_LANGUAGE_MENU_VISIBLE] (state, { isLanguageMenuVisible }) {
      state.isLanguageMenuVisible = isLanguageMenuVisible
    },
    [SET_IS_TRADE_MENU_VISIBLE] (state, { isTradeMenuVisible }) {
      state.isTradeMenuVisible = isTradeMenuVisible
    }
  },
  getters: {
    fiatCurrency: state => state.fiatCurrency,
    language: state => state.language,
    windowSize: state => state.windowSize
  },
  actions: {
    updateSessionTimer ({ state, commit, dispatch, rootGetters }) {
      const passport = rootGetters['passport/user']
      commit(CLEAR_SESSION_TIMER)
      if (passport) {
        const autoCloseModalTimeSeconds = config.autoCloseModalTimeSeconds
        state.timerId = setTimeout(() => {
          dispatch('modals/open', {
            factory: () => LogoutModal,
            data: {
              logoutTimeSeconds: config.logoutTimeSeconds
            }
          }, { root: true })
        }, autoCloseModalTimeSeconds * 100)
      }
    },
    resizeWindow ({ commit }, windowSize) {
      commit(WINDOW_SIZE, windowSize)
    },
    setIsPrivateToolbarOverflowed ({ commit }, { isPrivateToolbarOverflowed }) {
      commit(SET_IS_PRIVATE_TOOLBAR_OVERFLOWED, { isPrivateToolbarOverflowed })
    },
    setIsTradeMenuVisible ({ commit }, { isTradeMenuVisible }) {
      commit(SET_IS_TRADE_MENU_VISIBLE, { isTradeMenuVisible })
    },
    setLanguageMenuVisible ({ commit }, { isLanguageMenuVisible }) {
      commit(SET_IS_LANGUAGE_MENU_VISIBLE, { isLanguageMenuVisible })
    }
  }
})
