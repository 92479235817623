import Joi from 'joi'

export default class AbstractModel {
  constructor (data, scheme) {
    try {
      const schemas = []
      const getScheme = constructorFunc => {
        if (constructorFunc.schema) {
          schemas.push(constructorFunc.schema)
        }
        if (Object.getPrototypeOf(constructorFunc)) {
          getScheme(Object.getPrototypeOf(constructorFunc))
        }
      }

      if (scheme) {
        schemas.push(scheme)
      } else {
        getScheme(this.constructor)
      }

      const validationSchema = Joi.object(Object.assign({}, ...schemas.reverse()))

      const { error, value } = validationSchema.validate(
        data || {},
        {
          stripUnknown: true
        }
      )

      if (error) {
        throw new Error(`[${this.constructor.name}].${error}`)
      }

      Object.assign(this, value)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`[${this.constructor.name}]: ${e.message}. data: ${JSON.stringify(data)}`)
      throw e
    }
  }
}
