/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'affiliate': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 2.5a3.333 3.333 0 100 6.667A3.333 3.333 0 0010 2.5zm6.667 7.5v1c-.834.25-1.667 1-1.667 2.333 0 1.584 1.333 2.084 2.166 2.417 1 .417 1.167.5 1.167.917 0 .416-.166.833-.833.833-.667 0-.833-.417-.833-.833H15c0 .833.5 2 1.667 2.334V20h1.666v-1C19.5 18.668 20 17.5 20 16.668c0-1.584-1.333-2.084-2.166-2.417-1-.417-1.167-.5-1.167-.917 0-.666.416-.833.833-.833.667 0 .833.417.833.833H20c0-.833-.5-2-1.667-2.334V10h-1.666zM10 11.667c-2.617 0-7.5 1.288-7.5 3.75V17.5h10.925a4.097 4.097 0 01-.092-.833v-3.334a4.4 4.4 0 01.153-1.139c-1.23-.35-2.514-.527-3.486-.527z" _fill="#fff" fill-opacity=".7"/>'
  }
})
