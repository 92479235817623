import Joi from 'joi'
import BigNumber from 'bignumber.js'
import AbstractModel from './AbstractModel'
import { getScale } from 'src/utils'

export default class MarketModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      symbol: Joi.string().required(),
      name: Joi.string().required(),
      baseCurrency: Joi.string().required(),
      baseTokenAddress: Joi.string().required(),
      quoteCurrency: Joi.string().required(),
      quoteTokenAddress: Joi.string().required(),
      feeCurrency: Joi.string().allow(null),
      feeTokenAddress: Joi.string().required(),
      alternativeCurrency: Joi.string().allow(null),
      alternativeTokenAddress: Joi.string().allow(null),
      quantityIncrement: Joi.string().required(),
      tickSize: Joi.string().required(),
      makerFee: Joi.string().required(),
      takerFee: Joi.string().required(),
      makerAltFee: Joi.string().allow(null),
      takerAltFee: Joi.string().allow(null),
      baseMinSize: Joi.string().required(),
      quoteMinSize: Joi.string().required(),
      locked: Joi.boolean(),
      promotional: Joi.boolean(),
      showOnLanding: Joi.boolean(),
      sortOrder: Joi.number().required()
    }
  }

  get key () {
    return this.symbol
  }

  get tickSizeFormatNumber () {
    return getScale(this.tickSize)
  }

  get quantityIncrementFormatNumber () {
    return getScale(this.quantityIncrement)
  }

  get tickSizeQuantityIncrementComposition () {
    return new BigNumber(this.tickSize).times(this.quantityIncrement).toString()
  }

  get tickSizeQuantityIncrementCompositionFormatNumber () {
    return getScale(this.tickSizeQuantityIncrementComposition)
  }

  get tickSizeDp () {
    return new BigNumber(this.tickSize).dp()
  }

  static fromJson (data, context, options) {
    return new MarketModel({
      ...data
    }, { stripUnknown: true })
  }
}
