import {
  LoadableListModel,
  MarketFeeModel,
  MarketModel
} from 'src/models'
import { PLASMA } from 'src/remotes'

export const FEES_LOADING = 'fees/loading'
export const FEES_LOADED = 'fees/loaded'
export const MARKETS_LOADING = 'fess/markets/loading'
export const MARKETS_LOADED = 'fees/markets/loaded'

export default () => ({
  namespaced: true,
  state: {
    fees: new LoadableListModel(MarketFeeModel),
    markets: new LoadableListModel(MarketModel)
  },

  mutations: {
    [FEES_LOADING]: (state) => {
      state.fees = new LoadableListModel(MarketFeeModel, {
        isLoaded: false,
        isLoading: true,
        value: state.fees.value.map(MarketFeeModel.fromJson)
      })
    },
    [FEES_LOADED]: (state, { fees }) => {
      state.fees = new LoadableListModel(MarketFeeModel, {
        isLoaded: true,
        isLoading: false,
        value: fees && fees.map(MarketFeeModel.fromJson)
      })
    },
    [MARKETS_LOADING]: (state) => {
      state.markets = new LoadableListModel(MarketModel, {
        isLoaded: false,
        isLoading: true,
        value: state.markets.value.map(MarketModel.fromJson)
      })
    },
    [MARKETS_LOADED]: (state, { items }) => {
      state.markets = new LoadableListModel(MarketModel, {
        isLoaded: true,
        isLoading: false,
        value: items && items.map(MarketModel.fromJson)
      })
    }
  },
  getters: {
    fees: state => state.fees,
    isFeesLoaded: state => state.fees.isLoaded,
    isMarketsLoaded: state => state.markets.isLoaded,
    getMarketBySymbol: state => symbol => state.markets.isLoaded && state.markets.value.find(
      market => market.symbol === symbol
    )
  },
  actions: {
    async fetchFees ({ state, commit }) {
      const { data } = await PLASMA.get('public/markets/fees')
      return data.map(MarketFeeModel.fromJson)
    },
    async loadFees ({ state, commit, dispatch }) {
      commit(FEES_LOADING)
      const fees = await dispatch('fetchFees')
      commit(FEES_LOADED, { fees })
    },
    async fetchMarkets ({ state, commit }) {
      const { data } = await PLASMA.get('public/markets')
      return data.map(MarketModel.fromJson)
    },
    async loadMarkets ({ state, commit, dispatch }) {
      commit(MARKETS_LOADING)
      const items = await dispatch('fetchMarkets')
      commit(MARKETS_LOADED, { items })
    }
  }
})
