/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'twitter-2': {
    width: 23,
    height: 20,
    viewBox: '0 0 23 20',
    data: '<path pid="0" d="M20.718 4.961A8.812 8.812 0 0023 2.306a7.451 7.451 0 01-2.548.772A5.469 5.469 0 0022.434.31a9.574 9.574 0 01-3.026 1.206A4.718 4.718 0 0016.71.058 4.563 4.563 0 0013.727.6a5.001 5.001 0 00-2.088 2.331 5.424 5.424 0 00-.369 3.197 12.9 12.9 0 01-5.404-1.53A13.724 13.724 0 011.504.875a5.722 5.722 0 00-.443 3.639 5.462 5.462 0 001.84 3.102 4.963 4.963 0 01-2.016-.621 5.272 5.272 0 00.996 3.22 4.729 4.729 0 002.684 1.826 5.57 5.57 0 01-2.017.057c.29.998.865 1.875 1.645 2.51.78.634 1.726.994 2.707 1.03-1.964 1.607-4.439 2.337-6.9 2.034 2.22 1.627 4.879 2.44 7.572 2.316 7.803-.377 13.022-6.327 13.146-15.027z" _fill="#9B8AD8"/>'
  }
})
