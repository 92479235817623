import BigNumber from 'bignumber.js'

export default function (number, toFixed) {
  if (!number) {
    return 0
  }
  const bigNumber = new BigNumber(number)
  if ((toFixed >= 0) && bigNumber.dp() > 0) {
    return new BigNumber(bigNumber.toNumber()).toFormat(toFixed)
  }
  return bigNumber.toFormat()
}
