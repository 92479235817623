import Joi from 'joi'
import { AbstractModel } from '@/models/core'

export const schemaFactory = () => ({
  createdAt: Joi.date().required(),
  articles: Joi.array().items(Joi.object().keys({
    id: Joi.string().required(),

    publishDate: Joi.date().required(),

    title: Joi.string().required(),

    preview: Joi.object().keys({
      image: Joi.string().required(),
      imageAlt: Joi.string().required(),
      description: Joi.string().required()
    }),

    metainfo: Joi.object().keys({
      url: Joi.string().required()
    }),

    seo: Joi.object().keys({
      metatags: Joi.array().items(Joi.object().keys({
        name: Joi.string().required(),
        content: Joi.string().required()
      })).optional(),
      ogmetatags: Joi.array().items(Joi.object().keys({
        property: Joi.string().required(),
        content: Joi.string().required()
      })).optional()
    })
  })).required()
})

export default class ShortGameArticleModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return schemaFactory()
  }

  static fromJson (data) {
    return new ShortGameArticleModel({
      ...data,
      metainfo: {
        ...data.metainfo
      }
    }, { stripUnknown: true })
  }
}
