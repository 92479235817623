/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'youtube': {
    width: 26,
    height: 18,
    viewBox: '0 0 26 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M25.592 14.726c.241-1.85.373-3.712.396-5.578L26 8.853a47.764 47.764 0 00-.396-5.578 3.324 3.324 0 00-1.027-2.023A3.469 3.469 0 0022.47.324C21.484.182 16.768 0 13.005 0S4.516.182 3.53.324a3.466 3.466 0 00-2.107.927A3.322 3.322 0 00.396 3.274 47.759 47.759 0 000 8.85v.297c.023 1.866.155 3.728.396 5.578.093.77.456 1.485 1.027 2.023a3.469 3.469 0 002.107.927c.986.142 5.712.324 9.463.324 3.754 0 8.48-.182 9.465-.324a3.469 3.469 0 002.107-.927 3.324 3.324 0 001.027-2.023zM17 8.906L10 13V5l7 3.906z" _fill="#9B8AD8"/>'
  }
})
