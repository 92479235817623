import Joi from 'joi'
import { AbstractModel } from 'src/models/core'
import ShortBlogArticleModel from './ShortBlogArticleModel'

export default class BlogArticlesResponse extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      items: Joi.array().items(Joi.object().instance(ShortBlogArticleModel)).optional(),
      page: Joi.number().default(0),
      totalPages: Joi.number().default(0)
    }
  }

  static fromJson (data) {
    return new BlogArticlesResponse({
      items: data.items.map(ShortBlogArticleModel.fromJson),
      page: data.page,
      totalPages: data.totalPages
    })
  }
}
