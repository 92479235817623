import { LoadableListModel, PublicCoinInfoModel } from 'src/models'
import { getExchangeBackend } from 'src/remotes'

export const VOLUME_LOADING = 'volume/loading'
export const VOLUME_LOADED = 'volume/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      list: new LoadableListModel(PublicCoinInfoModel)
    }
  },
  mutations: {
    [VOLUME_LOADING]: (state) => {
      state.list = new LoadableListModel(PublicCoinInfoModel, {
        isLoaded: false,
        isLoading: true,
        value: []
      })
    },
    [VOLUME_LOADED]: (state, { data }) => {
      state.list = new LoadableListModel(PublicCoinInfoModel, {
        isLoaded: true,
        isLoading: false,
        value: data.map(PublicCoinInfoModel.fromJson)
      })
    }
  },
  actions: {
    async loadInfo ({ commit }) {
      commit(VOLUME_LOADING)
      const { data } = await getExchangeBackend().get('coins/info')
      commit(VOLUME_LOADED, { data })
    }
  }
})
