/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'about-us/icon_02': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<path pid="0" d="M19.7 7.754a1.273 1.273 0 011.805 0 1.273 1.273 0 010 1.806L9.562 21.504a1.273 1.273 0 01-1.805 0 1.273 1.273 0 010-1.806L19.7 7.754zm-2.928-2.928L4.828 16.771a5.424 5.424 0 00.003 7.657c2.099 2.099 5.537 2.125 7.658.004l11.944-11.944a5.424 5.424 0 00-.003-7.658c-2.147-2.099-5.562-2.1-7.658-.004z" _fill="#1C1C30" _stroke="#FDA431" stroke-width=".75" stroke-miterlimit="10"/>'
  }
})
