/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M16.198 10.813c.033-.27.05-.541.052-.813 0-.272-.02-.544-.062-.813l1.76-1.375a.431.431 0 00.104-.53l-1.667-2.885a.423.423 0 00-.51-.188l-2.072.833a6.103 6.103 0 00-1.407-.812l-.313-2.21a.416.416 0 00-.416-.353H8.333a.405.405 0 00-.406.354l-.313 2.208a6.266 6.266 0 00-1.406.813l-2.073-.834a.412.412 0 00-.51.188L1.957 7.28a.403.403 0 00.104.531l1.76 1.375a4.909 4.909 0 00-.01 1.626l-1.76 1.374a.43.43 0 00-.104.532l1.667 2.886a.424.424 0 00.51.188l2.073-.834c.43.332.903.606 1.405.813l.313 2.208a.424.424 0 00.417.353h3.334a.398.398 0 00.406-.354l.313-2.208a6.265 6.265 0 001.407-.813l2.072.834a.411.411 0 00.51-.188l1.668-2.885a.404.404 0 00-.105-.531l-1.74-1.376zM10 13.124A3.125 3.125 0 1113.125 10 3.134 3.134 0 0110 13.125z" _fill="#fff" fill-opacity=".7"/>'
  }
})
