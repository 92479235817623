import Joi from 'joi'
import BigNumber from 'bignumber.js'
import AbstractModel from './AbstractModel'

export default class RateModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      from: Joi.string().required(),
      to: Joi.string().required(),
      isLoading: Joi.boolean(),
      isLoaded: Joi.boolean(),
      price: Joi.string().allow(null)
    }
  }

  get amount () {
    return new BigNumber(this.price)
  }

  loaded ({ price }) {
    return new RateModel({
      isLoaded: true,
      isLoading: false,
      from: this.from,
      to: this.to,
      price
    })
  }

  loading () {
    return new RateModel({
      isLoaded: this.isLoaded,
      isLoading: true,
      from: this.from,
      to: this.to,
      price: this.price
    })
  }
}
