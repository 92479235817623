/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'linkedin-2': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M18.745 18.745h-3.26V13.64c0-1.217-.021-2.784-1.695-2.784-1.698 0-1.958 1.326-1.958 2.696v5.193h-3.26V8.247h3.13v1.435h.044a3.43 3.43 0 013.087-1.696c3.304 0 3.913 2.173 3.913 5l-.001 5.76zM4.895 6.813a1.892 1.892 0 11-.001-3.783 1.892 1.892 0 010 3.783zm1.63 11.933H3.26V8.248h3.264v10.498zM20.37.002H1.623A1.605 1.605 0 000 1.587v18.825A1.607 1.607 0 001.623 22H20.37A1.61 1.61 0 0022 20.412V1.586A1.609 1.609 0 0020.37 0" _fill="#9B8AD8"/>'
  }
})
