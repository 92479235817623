import Joi from 'joi'
import AbstractModel from './AbstractModel'

export default class PlasmaTickerDataModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      market: Joi.string().required(),
      period: Joi.string().default('v24'),
      timestamp: Joi.date().required(),
      bid: Joi.string().allow(null), // best BID
      ask: Joi.string().allow(null), // best ASK
      last: Joi.string().allow(null), // last trade
      low: Joi.string().allow(null),
      high: Joi.string().allow(null),
      open: Joi.string().allow(null),
      volume: Joi.string().allow(null),
      volumeQuote: Joi.string().allow(null)
    }
  }

  get symbol () {
    return this.market
  }

  static fromJson (data) {
    return new PlasmaTickerDataModel({
      ...data,
      timestamp: new Date(data.timestamp)
    })
  }
}
