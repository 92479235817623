
import {
  LoadableModel,
  LoadableListModel,
  ShortBlogArticleModel,
  BlogArticleModel,
  BlogCommentModel,
  BlogCategoryModel,

  BlogArticlesRequest,
  BlogArticlesResponse,
  BlogArticleCommentsRequest,
  BlogArticleCommentsResponse
} from 'src/models'
import { getPublicBackend, withAuthorization } from 'src/remotes'

const DEFAULT_ARTICLES_PAGE_SIZE = 10
const DEFAULT_COMMENTS_PAGE_SIZE = 3

export const ARTICLES_LOADING = 'items/loading'
export const ARTICLES_LOADED = 'items/loaded'

export const ARTICLE_LOADING = 'item/loading'
export const ARTICLE_LOADED = 'item/loaded'

export const COMMENTS_LOADING = 'comments/loading'
export const COMMENTS_LOADED = 'comments/loaded'

export const CATEGORIES_LOADING = 'categories/loading'
export const CATEGORIES_LOADED = 'categories/loaded'

export default () => ({
  namespaced: true,
  state () {
    return {
      DEFAULT_ARTICLES_PAGE_SIZE,
      DEFAULT_COMMENTS_PAGE_SIZE,

      page: 0,
      totalPages: 0,
      shortArticles: new LoadableListModel(ShortBlogArticleModel),

      article: new LoadableModel(BlogArticleModel),

      pageComments: 0,
      totalPagesComments: 0,
      countComments: 0,
      comments: new LoadableListModel(BlogCommentModel),

      categories: new LoadableListModel(BlogCategoryModel)
    }
  },
  mutations: {
    [ARTICLES_LOADING]: (state) => {
      state.shortArticles = new LoadableListModel(ShortBlogArticleModel, {
        isLoaded: false,
        isLoading: true,
        value: state.shortArticles.value.map(ShortBlogArticleModel.fromJson)
      })
    },
    [ARTICLES_LOADED]: (state, { articles, page, totalPages, append }) => {
      const newValue = append
        ? [...state.shortArticles.value, ...articles]
        : articles

      state.page = page
      state.totalPages = totalPages
      state.shortArticles = new LoadableListModel(ShortBlogArticleModel, {
        isLoaded: true,
        isLoading: false,
        value: newValue.map(ShortBlogArticleModel.fromJson)
      })
    },
    [ARTICLE_LOADING]: (state) => {
      state.article = new LoadableModel(BlogArticleModel, {
        isLoaded: false,
        isLoading: true,
        value: null
      })
    },
    [ARTICLE_LOADED]: (state, { article }) => {
      state.article = new LoadableModel(BlogArticleModel, {
        isLoaded: true,
        isLoading: false,
        value: article && BlogArticleModel.fromJson(article)
      })
    },
    [COMMENTS_LOADING]: (state) => {
      state.comments = new LoadableListModel(BlogCommentModel, {
        isLoaded: false,
        isLoading: true,
        value: state.comments.value.map(BlogCommentModel.fromJson)
      })
    },
    [COMMENTS_LOADED]: (state, { comments, page, totalPages, count, append }) => {
      const newValue = append
        ? [...state.comments.value, ...comments]
        : comments

      state.totalPagesComments = totalPages
      state.pageComments = page
      state.countComments = count
      state.comments = new LoadableListModel(BlogCommentModel, {
        isLoaded: true,
        isLoading: false,
        value: newValue.map(BlogCommentModel.fromJson)
      })
    },
    [CATEGORIES_LOADING]: (state) => {
      state.categories = new LoadableListModel(BlogCategoryModel, {
        isLoaded: false,
        isLoading: true,
        value: []
      })
    },
    [CATEGORIES_LOADED]: (state, { categories }) => {
      state.categories = new LoadableListModel(BlogCategoryModel, {
        isLoaded: true,
        isLoading: false,
        value: categories.map(BlogCategoryModel.fromJson)
      })
    }
  },
  actions: {
    async fetchArticles ({ dispatch, state }, { page, size, category, tags, query }) {
      let tagsArray = null

      if (tags) {
        tagsArray = Array.isArray(tags)
          ? tags
          : [tags]
      }

      const request = new BlogArticlesRequest({
        category,
        tags: tagsArray,
        query,
        page,
        size
      })
      const { data } = await getPublicBackend().get('/blog/articles', {
        params: request
      })
      return BlogArticlesResponse.fromJson(data)
    },
    async loadArticles ({ state, commit, dispatch }, { page = 0, size, category, tags, query, append }) {
      commit(ARTICLES_LOADING)
      const { items, totalPages } = await dispatch('fetchArticles', { page, size, category, tags, query })
      commit(ARTICLES_LOADED, { articles: items, totalPages, page, append })
    },
    async fetchArticle ({ dispatch }, { url }) {
      try {
        const { data } = await getPublicBackend().get(`/blog/articles/${url}`)
        return BlogArticleModel.fromJson(data)
      } catch (e) {
        return null
      }
    },
    async loadArticle ({ state, commit, dispatch }, { url }) {
      commit(ARTICLE_LOADING)
      try {
        const article = await dispatch('fetchArticle', { url })
        commit(ARTICLE_LOADED, { article })
      } catch (e) {
        commit(ARTICLE_LOADED, { article: null })
      }
    },
    async fetchComments ({ dispatch }, { url, page, size }) {
      const request = new BlogArticleCommentsRequest({
        page,
        size
      })
      const { data } = await getPublicBackend().get(`/blog/articles/${url}/comments`, {
        params: request
      })
      return BlogArticleCommentsResponse.fromJson(data)
    },
    async loadComments ({ state, commit, dispatch }, { page, size, url, append }) {
      commit(COMMENTS_LOADING)
      const {
        items,
        page: resultPage,
        totalPages,
        count
      } = await dispatch('fetchComments', { page, size, url })
      commit(COMMENTS_LOADED, {
        comments: items,
        page: resultPage,
        totalPages,
        count,
        append
      })
    },
    async blogSubscribe ({ dispatch }, { email }) {
      return getPublicBackend().post('/blog/articles/subscribe', {
        email
      })
    },
    async postComment ({ dispatch, state }, { url, name, comment }) {
      const token = await dispatch('passport/requireToken', null, { root: true })
      try {
        await getPublicBackend().post(`/blog/articles/${url}/comment`, {
          name,
          comment
        }, withAuthorization(token))
        await dispatch('loadComments', {
          url,
          page: 0,
          size: state.DEFAULT_COMMENTS_PAGE_SIZE
        })
      } catch (e) {
        throw new Error('Cannot add comment')
      }
    },

    async fetchCategories ({ dispatch, state }) {
      const { data } = await getPublicBackend().get('/blog/categories')
      return data.map(BlogCategoryModel.fromJson)
    },
    async loadCategories ({ commit, dispatch }) {
      commit(CATEGORIES_LOADING)
      const categories = await dispatch('fetchCategories')
      commit(CATEGORIES_LOADED, { categories })
    }
  }
})
