/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wallet': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.5 15v.833a1.671 1.671 0 01-1.667 1.667H4.167A1.667 1.667 0 012.5 15.833V4.167A1.667 1.667 0 014.167 2.5h11.666A1.672 1.672 0 0117.5 4.167V5H10a1.667 1.667 0 00-1.667 1.667v6.666A1.666 1.666 0 0010 15h7.5zM10 13.333h8.333V6.667H10v6.666zm3.333-2.083a1.25 1.25 0 110-2.499 1.25 1.25 0 010 2.499z" _fill="#fff" fill-opacity=".7"/>'
  }
})
