import Joi from 'joi'
import AbstractModel from './AbstractModel'
import BigNumber from 'bignumber.js'

export default class PublicPlasmaTickerDataModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      market: Joi.string().required(),
      period: Joi.string().default('v24'),
      timestamp: Joi.date().required(),
      bid: Joi.string().allow(null), // best BID
      ask: Joi.string().allow(null), // best ASK
      last: Joi.string().allow(null), // last trade
      low: Joi.string().allow(null),
      high: Joi.string().allow(null),
      open: Joi.string().allow(null),
      volume: Joi.string().allow(null),
      volumeQuote: Joi.string().allow(null)
    }
  }

  get symbol () {
    return this.market
  }

  get change () {
    if (this.last == null || this.open === null) {
      return null
    }

    const isOpenZero = new BigNumber(this.open).isZero()
    if (isOpenZero &&
      this.last && !new BigNumber(this.last).isZero()
    ) {
      return new BigNumber(100)
    }

    if (isOpenZero) {
      return null
    }

    return new BigNumber(this.last)
      .minus(this.open)
      .div(this.open)
      .multipliedBy(100)
  }

  get changeAbs () {
    const change = this.change
    return change && change.abs()
  }

  static fromJson (data) {
    return new PublicPlasmaTickerDataModel({
      ...data,
      timestamp: new Date(data.timestamp)
    })
  }
}
