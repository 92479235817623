import PassportClient from './lib/PassportClient'
import SocketClient from './lib/SocketClient'

export const plasmaSocketClientFactory = config => {
  return new SocketClient({
    url: config.plasmaService.socket
  }).start()
}

export const ratesSocketClientFactory = config => {
  return new SocketClient({
    url: config.ratesService.socket
  }).start()
}

export const passportClientFactory = config => {
  return new PassportClient(config.passportService.endpoint)
}
export class Registry {
  constructor () {
    this.services = {}
    this.devices = {}
    this.plugins = {}
  }

  registerService (name, service) {
    this.services[name] = service
    return this
  }

  registerDevice (name, device) {
    this.devices[name] = device
    return this
  }

  registerPlugin (name, plugin) {
    this.plugins[name] = plugin
    return this
  }

  getService (name) {
    return this.services[name]
  }

  getDevice (name) {
    return this.devices[name]
  }

  getPlugin (name) {
    return this.plugins[name]
  }

  getAllServices () {
    return this.services
  }

  getAllDevices () {
    return this.devices
  }

  getAllPlugins () {
    return this.plugins
  }
}
export const REGISTRY = new Registry()
