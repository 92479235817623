import Joi from 'joi'
import { AbstractModel } from 'src/models/core'
import BlogCategoryModel from './BlogCategoryModel'
import { schemaFactory as shortBlogArticleModelSchemaFactory } from './ShortBlogArticleModel'

export default class BlogArticleModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      ...shortBlogArticleModelSchemaFactory(),
      content: Joi.string().required()
    }
  }

  static fromJson (data) {
    return new BlogArticleModel({
      ...data,
      metainfo: {
        ...data.metainfo,
        categories: BlogCategoryModel.fromJson(data.metainfo.categories)
      }
    })
  }
}
