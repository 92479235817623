/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 14c-3 0-9 1.508-9 4.5V21h18v-2.5c0-2.992-6-4.5-9-4.5zm5-7a5 5 0 1 0-9.999 0A5 5 0 0 0 17 7z" _fill="#000"/>'
  }
})
