const UPDATE_VERSION = 'version/update'

export default () => ({
  namespaced: true,
  state: {
    currentTimeXVersion: null
  },
  getters: {
    currentTimeXVersion: state => state.currentTimeXVersion
  },
  mutations: {
    [UPDATE_VERSION] (state, { version }) {
      state.currentTimeXVersion = version
    }
  },
  actions: {
    updateVersion ({ commit }, { version }) {
      commit(UPDATE_VERSION, { version })
    }
  }
})
