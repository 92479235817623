import { getPublicBackend } from 'src/remotes'

const UPDATE_TOPICS = 'topics/update'
const UPDATE_QUESTIONS = 'questions/update'

export default () => ({
  namespaced: true,
  state: {
    topics: [],
    searchedQuestions: []
  },
  mutations: {
    [UPDATE_TOPICS] (state, topics) {
      state.topics = topics
    },
    [UPDATE_QUESTIONS] (state, searchedQuestions) {
      state.searchedQuestions = searchedQuestions
    }
  },
  actions: {
    async getTopics ({ state, commit, dispatch }) {
      const { data } = await getPublicBackend().get('/faq/topics')
      if (data) {
        commit(UPDATE_TOPICS, data)
      }
    },
    async searchQuestions ({ state, commit, dispatch }, { locale, query }) {
      const { data } = await getPublicBackend().get('/faq/questions/search', {
        params: {
          locale,
          query
        }
      })
      if (data && data.elements) {
        const questions = data.elements.map(element => element.document)
        commit(UPDATE_QUESTIONS, questions)
      }
    }
  }
})
