/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'aud': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21 37.1c8.892 0 16.1-7.208 16.1-16.1 0-8.892-7.208-16.1-16.1-16.1-8.892 0-16.1 7.208-16.1 16.1 0 8.892 7.208 16.1 16.1 16.1zm0 1.4c9.665 0 17.5-7.835 17.5-17.5S30.665 3.5 21 3.5 3.5 11.335 3.5 21 11.335 38.5 21 38.5z" _fill="#C4C4CF"/><path pid="1" d="M26.881 29l-2.024-4.866h-5.252l.492-1.1h4.268l-.876-1.987h-2.516l.492-1.152h1.587l-2.025-4.709L15.064 29H12l7.769-18h2.571l3.776 8.895h3.392v1.152h-2.9l.82 1.988h2.08v1.099H27.92L30 29h-3.119z" _fill="#7360C5"/>'
  }
})
