import Joi from 'joi'
import AbstractModel from './AbstractModel'
import MarketModel from './MarketModel'
import PublicMarketDAO from 'src/daos/lib/PublicMarketDAO'

export default class PublicMarketDAOModel extends AbstractModel {
  constructor (data) {
    super(data)
    Object.freeze(this)
  }

  static get schema () {
    return {
      market: Joi.object().instance(MarketModel).required(),
      dao: Joi.object().instance(PublicMarketDAO).required()
    }
  }

  get key () {
    return this.market.symbol
  }

  static fromMarketModel (market) {
    return new PublicMarketDAOModel({
      market,
      dao: new PublicMarketDAO({ market })
    })
  }
}
