/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left-circle': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" opacity=".5" fill-rule="evenodd" clip-rule="evenodd" d="M21.5 37.6c8.892 0 16.1-7.208 16.1-16.1 0-8.892-7.208-16.1-16.1-16.1-8.892 0-16.1 7.208-16.1 16.1 0 8.892 7.208 16.1 16.1 16.1zm0 1.4C31.165 39 39 31.165 39 21.5S31.165 4 21.5 4 4 11.835 4 21.5 11.835 39 21.5 39z" _fill="#fff"/><path pid="1" d="M26 15.005L24.092 13 16 21.5l8.092 8.5L26 27.995 19.817 21.5 26 15.005z" _fill="#fff"/>'
  }
})
