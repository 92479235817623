/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'new-calendar': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M13.333.833V2.5H6.667V.833H5V2.5h-.833a1.66 1.66 0 00-1.659 1.667L2.5 15.833A1.667 1.667 0 004.167 17.5h11.666a1.671 1.671 0 001.667-1.667V4.167A1.671 1.671 0 0015.833 2.5H15V.833h-1.667zm2.5 15H4.167V6.667h11.666v9.166z" _fill="#fff" fill-opacity=".7"/>'
  }
})
