import { mapActions } from 'vuex'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    onClose: Function,
    white: Boolean,
    purple: Boolean
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close'
    }),
    async close () {
      if (this.onClose) {
        await this.onClose()
      }
      this.handleClose()
    }
  }
}
