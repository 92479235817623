var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root-tx-button",class:{
		'dark': _vm.theme === 'dark',
    'light': _vm.theme === 'light',
    'bordered': _vm.theme === 'bordered',
    'transparent': _vm.theme === 'transparent',
    'small': _vm.size === 'small',
    'medium': _vm.size === 'medium',
    'large': _vm.size === 'large',
    'full-width': _vm.fullWidth,
    'auto-width': _vm.autoWidth,
    'disabled' : _vm.disabled
  },on:{"click":_vm.onClick}},[_c('div',{staticClass:"tx-button"},[(_vm.loading)?_c('div',{staticClass:"loading-container"},[_c('spinner',{attrs:{"theme":_vm.spinnerTheme,"size":"24"}})],1):_vm._e(),_c('div',{class:{'hide': _vm.loading}},[_vm._t("default",[_vm._v(_vm._s(_vm.title))])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }